import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { currentUserSelector, logout } from '../redux/reducers/userSlice';
import { AppThunkDispatch, useAppSelector } from '../redux/store';

interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  collapsed?: boolean;
}

const SidebarFooter: React.FC<SidebarFooterProps> = ({
  children,
  collapsed,
  ...rest
}) => {
  const currentUser = useAppSelector(currentUserSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch() as AppThunkDispatch;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '20px 0px',
      }}
    >
      {collapsed ? (
        <Menu>
          <SubMenu
            icon={
              <Avatar
                name={currentUser?.firstName + ' ' + currentUser?.lastName}
                size="30"
                round
              />
            }
          >
            <MenuItem
              onClick={() => {
                navigate('/user/detail');
              }}
            >
              {t('general.buttons.detail')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(logout());
                navigate('/');
              }}
            >
              {t('general.actions.logout')}
            </MenuItem>
          </SubMenu>
        </Menu>
      ) : (
        <Menu style={{ width: '100%' }}>
          <SubMenu
            label={t('sidebar.myprofile')}
            icon={
              <Avatar
                name={currentUser?.firstName + ' ' + currentUser?.lastName}
                size="30"
                round
              />
            }
          >
            <MenuItem
              onClick={() => {
                navigate('/user/detail');
              }}
            >
              {t('general.buttons.detail')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(logout());
                navigate('/');
              }}
            >
              {t('general.actions.logout')}
            </MenuItem>
          </SubMenu>
        </Menu>
      )}
    </div>
  );
};

export default SidebarFooter;
