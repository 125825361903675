import { Theme as Bootstrap4Theme } from '@rjsf/bootstrap-4';
import { withTheme } from '@rjsf/core';
import { t } from 'i18next';
import {
  ArrayFieldTemplate,
  FieldTemplate,
  ObjectFieldTemplate,
} from '../components/TemplateFieldShifter';

export const templates = {
  FieldTemplate,
  ArrayFieldTemplate,
  ObjectFieldTemplate,
};

export const FormWithBootstrap = withTheme(Bootstrap4Theme);

export const transformErrors = (errors: any) => {
  // console.log('errors', errors);
  return errors.map((error: any) => {
    if (error.name === 'required') {
      error.message = error.message.replace(
        'must have required property',
         t('general.errors.required'), 
      );
    }
    if (error.name === 'minLength') {
      error.message = t('general.errors.minLength', { 'limit': error.params.limit });
    }
    if (error.name === 'minItems') {
      error.message = t('general.errors.minItems', { 'limit': error.params.limit });
    }
    if (error.name === 'format' && error.params.format === 'email') {
      error.message =  t('general.errors.invalid_email');
    }
    if (error.name === 'pattern' && error.property === '.zip') {
      error.message = t('general.errors.cap');
    }
    if (error.name === 'pattern' && error.property === '.fiscalCode') {
      error.message = t('general.errors.fiscalCode');
    }
    if (error.name === 'pattern' && ( error.property === '.referentPhoneNumbers' || error.property === '.phoneNumber')) {
      error.message = t('general.errors.phoneNumber');
    }
    
    return error;
  });
};
