import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createDetailRealEstateAgencyConf,
  getDetailRealEstateAgencyConf,
  updateDetailRealEstateAgencyConf,
} from '../../api/realEstateAgencyAPI';
import BreadcrumbsUtility from '../../components/breadcrumb';
import { ErrorsView } from '../../components/errors.view';
import useRequest from '../../hook/use-request.hook';
import { ApiError } from '../../model/errors.model';
import { DetailRealEstateAgency } from '../../model/real-estate-agency.model';
import { currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import {
  NotificationTypes,
  useAppUtilContext,
} from '../../util/app-util.context';
import {
  FormWithBootstrap,
  templates,
  transformErrors,
} from '../../util/form-util';
import { Button } from 'reactstrap';
import { templateTableEditClass } from '../../util/form.util';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function RealEstateDetailPage(props: any) {
  const { showNotification } = useAppUtilContext();
  const { edit } = props;
  
  let { id } = useParams();
  const navigate = useNavigate();
  const { onErrorsModal, showModal } = useAppUtilContext();
  const { fetchData: fetchDataDetail } = useRequest<any>();
  const [entity, setEntity] = useState<DetailRealEstateAgency>();
  const { loading, fetchData } = useRequest();

  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailRealEstateAgencyConf(id),
        onSuccessDetail,
        onErrorsModal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccessDetail = (dto: DetailRealEstateAgency) => {
    setEntity(dto);
  };

  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;

  const datas = { ...entity };
  const schema: RJSFSchema = {
    type: 'object',
    required: ['name'],
    properties: {
      id: {
        type: 'number',
        title: t('real-estate-agency.id'),
      },
      name: {
        type: 'string',
        title: t('real-estate-agency.name'),
      },
      address: {
        type: 'string',
        title: t('real-estate-agency.address'),
      },
      city: {
        type: 'string',
        title: t('real-estate-agency.city'),
      },
      zip: {
        type: 'string',
        title: t('real-estate-agency.zip'),
        pattern: "^[0-9]{6}$"
      },
      referentName: {
        type: 'string',
        title: t('real-estate-agency.referentName'),
      },
      referentSurname: {
        type: 'string',
        title: t('real-estate-agency.referentSurname'),
      },
      referentPhoneNumbers: {
        type: 'array',
        title: t('real-estate-agency.referentPhoneNumbers'),
        items: {
          type: 'string',
          pattern: "^/[\+][0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/",
          description: t('general.desc.tel'),
        },
      },
      referentEmails: {
        type: 'array',
        title: t('real-estate-agency.referentEmails'),
        items: {
          type: 'string',
          format: "email",
        },
      },
    },
    additionalProperties: false,
  };

  const uiSchema = {
    id: { 'ui:widget': 'hidden' },
    "ui:options":{"customTemplate":"MultiColumns","cols":{
     "city":6,"zip":6,
     "referentName":6,"referentSurname":6
    }},
    "referentPhoneNumbers": {"ui:options":{"customTemplate":"CustomButtons", "orderable":false,"addButtonText":"Aggiungi Numero","removeButtonText":"Rimuovi"}}
    ,"referentEmails": {"ui:options":{"customTemplate":"CustomButtons","orderable":false,"addButtonText":"Aggiungi Email","removeButtonText":"Rimuovi"}}
  };
  const onErrors = (errors: any) => {
    // console.log('errors', errors);
    showNotification(errors[0].message, NotificationTypes.ERROR); // Shows first error message in errors array (missing passwords, etc.)
  };

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    if (isAdministrator || isSportellista) navigate('/real-estate-agency');
  };

  const onFormSubmit = ({ formData }: any) => {
    if (id) {
      fetchData(
        updateDetailRealEstateAgencyConf(formData),
        onSuccess,
        onErrors
      );
    } else {
      fetchData(
        createDetailRealEstateAgencyConf(formData),
        onSuccess,
        onErrors
      );
    }
  };
  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          {
            text: t('real-estate-agency.list.title'),
            link: '/real-estate-agency',
          },
          { text: t('real-estate-agency.detail.title') },
        ]}
      />
      <div className="header-container">
        <h2 className="page-title">{t('real-estate-agency.detail.title')}</h2>

        {(isAdministrator || isSportellista) && id && !edit && (
              <Button
                onClick={() => navigate(`/real-estate-agency/${id}/edit`)}
                color={templateTableEditClass}
                style={{ float: 'right', marginTop: '5px' }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: '1rem' }}
                  icon={faPencil}
                />{' '}
                {t('general.buttons.edit')}
              </Button>
            )}
      </div>
      <FormWithBootstrap
        templates={templates}
        onSubmit={onFormSubmit}
        formData={datas}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        transformErrors={transformErrors}
        showErrorList={false}
        readonly={!edit}
        children={!edit}
      ></FormWithBootstrap>
    </div>
  );
}
