import { Route } from 'react-router-dom';
import { currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
// import SurveyTemplateListPage from './survey-template-list.page';
// import SurveyTemplateDetailPage from './survey-template-detail.page';
// import SurveyTemplateEditPage from './survey-template-edit.page';
// import SurveyTemplateAnswerPage from './survey-template-answer.page';
import AppointmentCreatePage from './appointment-create.page';
import HomeAppointmentListPage from '../home/appointment-list.page';

const AppointmentRoutes = () => {

  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator
  const isSportellista = currentUser?.authorityChecks.isSportellista 
  const isProfessionista = currentUser?.authorityChecks.isProfessionista
  const isOperatore = currentUser?.authorityChecks.isOperatore 

  return (
    <ErrorBoundaryRoutes>
      {currentUser && ( isSportellista || isProfessionista || isAdministrator || isOperatore) && (
        <>
          <Route path="" element={<div className='container'><HomeAppointmentListPage fullCalendar monthView withForm /></div>} />
          { ( isSportellista || isProfessionista || isAdministrator ) && <Route path="create" element={<AppointmentCreatePage />} /> }
         { ( isSportellista || isProfessionista || isAdministrator ) && <Route path=":id/edit" element={<AppointmentCreatePage />} /> }
         { ( isSportellista || isProfessionista || isAdministrator ) && <Route path=":id" element={<AppointmentCreatePage readonly />} /> }
          {/* 
          <Route path=":id" element={<SurveyTemplateDetailPage />} />
          */}
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default AppointmentRoutes;
