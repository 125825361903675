import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import {
  createDetailAppointmentConf,
  getDetailAppointmentConf,
  updateDetailAppointmentConf,
} from '../../api/appointmentAPI';
import { getUserListConf } from '../../api/userAPI';
import BreadcrumbsUtility from '../../components/breadcrumb';
import useRequest from '../../hook/use-request.hook';
import { DetailUser } from '../../model/user.model';
import {
  Authorities,
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import {
  NotificationTypes,
  useAppUtilContext,
} from '../../util/app-util.context';
import { registerRs, templateTableEditClass } from '../../util/form.util';
import AddApointmentDocument from './add-appointment-document.view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

export default function AppointmentCreatePage(params: any) {
  let { id } = useParams();
  const { readonly } = params;
  const { t } = useTranslation();
  const { showModal } = useAppUtilContext();
  const { showNotification } = useAppUtilContext();
  const currentUser = useAppSelector(currentUserSelector);
  const [entity, setEntity] = useState<any>({});
  const [professionisti, setProfessionisti] = useState<DetailUser[]>([]);
  const [user, setUser] = useState<DetailUser[]>([]);
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();

  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isProfessionista = currentUser?.authorityChecks.isProfessionista;

  useEffect(() => {
    if (id) {
      fetchData(
        getDetailAppointmentConf(id),
        (data: any, header: any) => {
          setEntity({
            ...data,
            ...{
              professionistaId: data.professionista.id,
              userId: data.user.id,
            },
          });
        },
        onErrors
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchData(
      getUserListConf(1, 1000, '&authorities=' + Authorities.PROFESSIONISTA),
      (data: any, header: any) => {
        setProfessionisti(data);
      },
      onErrors
    );
    fetchData(
      getUserListConf(1, 1000, '&authorities=' + Authorities.USER),
      (data: any, header: any) => {
        setUser(data);
      },
      onErrors
    );
  }, []);

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    if (isAdministrator || isSportellista) navigate('/appointment');
  };

  const onErrors = (errors: any) => {
    // console.log('errors', errors);
    showNotification(errors[0].message, NotificationTypes.ERROR); // Shows first error message in errors array (missing passwords, etc.)
  };

  const onSubmit = (data: any) => {
    const { professionistaId, userId, title, date, id } = data;

//    console.log('onSubmit ---', userId);

    let pId = isProfessionista
      ? currentUser.id
      : parseInt(professionistaId, 10);

    if (id) {
      fetchData(
        updateDetailAppointmentConf({
          id,
          userId: parseInt(userId, 10),
          professionistaId: pId,
          title,
          date,
        }),
        onSuccess,
        onErrors
      );
    } else {
      fetchData(
        createDetailAppointmentConf({
          userId: parseInt(userId, 10),
          professionistaId: pId,
          title,
          date,
        }),
        onSuccess,
        onErrors
      );
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({ defaultValues: {}, values: { ...entity } });

  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          { text: t('appointment.list.title'), link: '/appointment' },
          {
            text: id ? ( readonly ? t('appointment.detail') : t('appointment.edit.title') ) : t('appointment.create.title')
          },
        ]}
      />

      <div className="header-container">
        <h2 className="page-title">
          {' '}
          {id ? ( readonly ? t('appointment.detail') : t('appointment.edit.title') ) : t('appointment.create.title')}

          {(isAdministrator || isSportellista) && id && readonly && (
              <Button
                onClick={() => navigate(`/appointment/${id}/edit`)}
                color={templateTableEditClass}
                style={{ float: 'right', marginTop: '5px' }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: '1rem' }}
                  icon={faPencil}
                />{' '}
                {t('general.buttons.edit')}
              </Button>
            )}
        </h2>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className="form-field">
          <Input
            id="title"
            name="title"
            type="text"
            disabled={readonly}
            {...registerRs(register, 'title', {
              required: t(`general.errors.required`),
            })}
            invalid={errors.title && errors.title != null}
          />{' '}
          <Label className="form-label" for="lastName">
            {t('appointment.title')}
          </Label>
          <FormFeedback>{'' + errors?.title?.message}</FormFeedback>
        </FormGroup>

        <FormGroup className="form-field">
          <Input
            id="date"
            name="date"
            type="datetime-local"
            disabled={readonly}
            placeholder={t('user.register.last_name_ph')}
            {...registerRs(register, 'date', {
              required: t(`general.errors.required`),
            })}
            invalid={errors.date && errors.date != null}
          />{' '}
          <Label className="form-label" for="lastName">
            {t('appointment.date')}
          </Label>
          <FormFeedback>{'' + errors?.date?.message}</FormFeedback>
        </FormGroup>
        {!isProfessionista && (
          <FormGroup className="form-field">
            <Input
              id="professionistaId"
              name="professionistaId"
              type="select"
              disabled={readonly}
              {...registerRs(register, 'professionistaId', {
                required: t(`general.errors.required`),
              })}
              invalid={
                errors.professionistaId && errors.professionistaId != null
              }
            >
              {professionisti.map((it) => {
                return (
                  <option key={it.id} value={it.id}>
                    {it.firstName == null && it.lastName == null
                      ? it.fiscalCode
                      : it.firstName + ' ' + it.lastName}
                  </option>
                );
              })}
            </Input>
            <Label className="form-label select" for="professionistaId">
              {t('appointment.professionista')}
            </Label>
            <FormFeedback>
              {'' + errors?.professionistaId?.message}
            </FormFeedback>
          </FormGroup>
        )}
        <FormGroup className="form-field">
          <Input
            id="userId"
            name="userId"
            type="select"
            disabled={readonly}
            {...registerRs(register, 'userId', {
              required: t(`general.errors.required`),
            })}
            invalid={errors.userId && errors.userId != null}
          >
            {user.map((it) => {
              return (
                <option key={it.id} value={it.id}>
                  {it.firstName == null && it.lastName == null
                    ? it.fiscalCode
                    : it.firstName + ' ' + it.lastName}
                </option>
              );
            })}
          </Input>
          <Label className="form-label select" for="userId">
            {t('appointment.user')}
          </Label>
          <FormFeedback>{'' + errors?.userId?.message}</FormFeedback>
        </FormGroup>

     {!readonly && <Button type="submit" color="primary" disabled={loading}>
          {!loading ? (
            t('general.actions.send')
          ) : (
            <>
              {/* <Spinner size="sm">Loading...</Spinner>
              <span> {t('general.actions.loading')}</span> */}
                <svg viewBox="25 25 50 50" className='svgcircle d-none d-md-inline mt-2'>
                <circle r="20" cy="50" cx="50"></circle>
              </svg>
            </>
          )}
        </Button> } 
      </Form>

      { !isSportellista && readonly && <AddApointmentDocument />}
    </div>
  );
}
