
import { CreateApointmentDocument, DetailApointmentDocument } from '../model/appointment-document.model';
import { defaultHeaders } from './common';

export const getAppointmentDocumentListConf = (
  query: string
) => {
  return {
    method: 'get',
    url:
      '/api/appointment-document' + query ,
    headers: defaultHeaders,
  };
};

export const getDetailAppointmentDocumentConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/appointment-document/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailAppointmentDocumentConf = (
  appointmentDocument: DetailApointmentDocument
) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: `/api/appointment-document/${appointmentDocument.id}`,
    headers: defaultHeaders,
    data:  appointmentDocument
  };
};

export const createDetailAppointmentDocumentConf = (
  appointmentDocument: CreateApointmentDocument
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: `/api/appointment-document`,
    headers: defaultHeaders,
    data: appointmentDocument,
  };
};
export const deleteAppointmentDocumentConf = (
  id: string
) => {
  return {
    method: 'delete',
    maxBodyLength: Infinity,
    url: `/api/appointment-document/`+id,
    headers: defaultHeaders
  };
};
