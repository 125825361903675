import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import useRequest from '../../hook/use-request.hook';
import { useAppUtilContext } from '../../util/app-util.context';
import { getActivateConf } from '../../api/userAPI';

export default function ActivatePage() {
  let { code } = useParams();
  const { showModal, onErrorsModal } = useAppUtilContext();
  const { t } = useTranslation();
  const { loading, fetchData } = useRequest();

  const navigate = useNavigate();

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    navigate('/');
  };

  useEffect(() => {
    if (code) {
      fetchData(getActivateConf(code), onSuccess, onErrorsModal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);
  return (
    <div className="container">
      {!loading && (
        <>
          {/* <Spinner size="sm">Loading...</Spinner>
          <span> {t('general.actions.loading')}</span> */}
            <svg viewBox="25 25 50 50" className='svgcircle d-none d-md-inline mt-2'>
              <circle r="20" cy="50" cx="50"></circle>
            </svg>
        </>
      )}
    </div>
  );
}
