import { useRef, useState } from "react";
import { withTheme } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";

import { Theme as Bootstrap4Theme } from "@rjsf/bootstrap-4";
import { RJSFSchema } from "@rjsf/utils";
import {
  ArrayFieldTemplate,
  FieldTemplate,
  ObjectFieldTemplate,
} from "../../components/TemplateFieldShifter";
import { DetailSurveyAnswer } from "../../model/survey-answer.model";
import { Button, Card, CardBody, Collapse } from "reactstrap";
import MobileButton from "../../components/mobile-button";
import { faGear, faLanguage } from "@fortawesome/free-solid-svg-icons";

const FormWithBootstrap = withTheme(Bootstrap4Theme);

export default function JSONFieldGenerator() {
  const formRef = useRef<any>(null);
  const [entity, setEntity] = useState<DetailSurveyAnswer>();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onChange = (props: any) => {
    const { formData } = props;

    setEntity(formData);
  };

  const onSubmit = (ev: any) => {
    const { formData } = ev

    let schema: any = {
      title: formData.title,
      type: formData.type,
      description: formData.description,
      default: formData.default,
      format: formData.format,
      minLenght: formData.minLenght,
      maxLenght: formData.maxLenght,
      enum: formData.enum ? formData.enum.split("\n") : undefined,
    };
    let ui: any = {
      'ui:placeholder': formData.description,

    };

    if (formData.type == "object") {
      schema = { ...schema, ...{ properties: {} } };
    }
    if (formData.type == "array") {
      schema = { ...schema, ...{ items: {} } };
    }

    setEntity({ ...formData, ...{ schema: JSON.stringify(schema), uiSchema: JSON.stringify(ui) } });
  };

  const schema: RJSFSchema = {
    type: "object",
    title: "",
    properties: {
      type: {
        enum: ["string", "object", "number", "array", "boolean", "integer"],
        title: "Type",
        type: "string",
      },
      title: {
        title: "Title",
        type: "string",
      },
      description: {
        title: "Description",
        type: "string",
      },
      default: {
        title: "Default",
        type: "string",
      },
      minLenght: {
        title: "Min lenght",
        type: "string",
      },
      maxLenght: {
        title: "Max lenght",
        type: "string",
      },
      enum: {
        title: "Enum",
        type: "string",
        description: "Un valore per riga",
      },
      format: {
        enum: ["date", "date-tile", "email", "radio"],
        title: "Format",
        type: "string",
      },
      schema: {
        title: "Schema",
        type: "string",
      },
      uiSchema: {
        title: "UI Schema",
        type: "string",
      },
    },
    required: ["title", "type"],
  };

  const uiSchema: any = {
    "ui:options": {
      customTemplate: "MultiColumns",
      cols: {
        type: 3,
        title: 3,
        description: 3,
        default: 3,
        maxLenght: 3,
        minLenght: 3,
        enum: 3,
        format: 3,
        uiSchema: 6,
        schema: 6,
      },
    },
    schema: {
      "ui:options": {
        customTemplate: "code",
        language: "json",
        heigth: 200,
      },
    },
    uiSchema: {
      "ui:options": {
        customTemplate: "code",
        language: "json",
        heigth: 200,
      },
    },
    enum: {
      "ui:widget": "textarea",
    },
    "ui:order": [
      "type",
      "title",
      "description",
      "default",
      "minLenght",
      "maxLenght",
      "enum",
      "format",
      "schema",
      "uiSchema",
    ],
  };
  const templates = {
    FieldTemplate,
    ArrayFieldTemplate,
    ObjectFieldTemplate,
  };


  const translateData = (ev: any) => {
    const xx = formRef.current?.validateForm();

    if(xx){
      onSubmit({ formData: entity })
    }
  };


  return (
    <div style={{ marginBottom: "1rem" }}>
      <Button color="info" onClick={toggle} style={{ marginBottom: "1rem" }}>
        Apri generatore JSON
      </Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <FormWithBootstrap
              ref={formRef}
              formData={entity}
              onSubmit={onSubmit}
              onChange={onChange}
              schema={schema}
              templates={templates}
              uiSchema={uiSchema}
              validator={validator}
            >
                  <MobileButton
              icon={faGear}
              color={'info'}
              type="button"
              onClick={translateData}
              text={'Genera JSON'}
            />
            
            </FormWithBootstrap>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}
