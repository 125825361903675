import {
  CreateAppointment,
  DetailAppointment,
} from '../model/appointment.model';
import { defaultHeaders } from './common';

export const getAppointmentListConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: 'get',
    url:
      '/api/appointment?page=' +
      page +
      '&size=' +
      size +
      query +
      '&sort=date,asc',
    headers: defaultHeaders,
  };
};

export const getAppointmentListAllConf = (query: string) => {
  return {
    method: 'get',
    url: '/api/appointment?' + query + '&sort=date,asc',
    headers: defaultHeaders,
  };
};

export const getDetailAppointmentConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/appointment/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailAppointmentConf = (appointment: DetailAppointment) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: `/api/appointment/${appointment.id}`,
    headers: defaultHeaders,
    data: JSON.stringify(appointment),
  };
};

export const createDetailAppointmentConf = (appointment: CreateAppointment) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: `/api/appointment`,
    headers: defaultHeaders,
    data: JSON.stringify(appointment),
  };
};

export const getAppointmentList = () => {
  return {
    method: 'get',
    url: '/api/appointment',
    headers: defaultHeaders,
  };
};
