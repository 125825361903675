import { faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from 'reactstrap';
import { checkRequiredAuthorities } from '../components/authority-boundary-view';
import i18n from '../i18n';
import {
  Authorities,
  changeUserLanguage,
  currentUserSelector,
  logout,
  userAccessTokenSelector,
  userLngSelector,
} from '../redux/reducers/userSlice';
import { AppThunkDispatch, useAppSelector } from '../redux/store';
import AppMenu from './menu';

export default function AppNavBar() {
  const currentUser = useAppSelector(currentUserSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch() as AppThunkDispatch;
  const accessToken = useAppSelector(userAccessTokenSelector);
  const { t } = useTranslation();
  const [screenWidth] = useState(window.innerWidth);
  const initialCollapsed = screenWidth < 1000;
  const [collapsed, setCollapsed] = useState(!initialCollapsed);
  const lang = useAppSelector(userLngSelector);

  const isAdministratorOrAssistente = checkRequiredAuthorities(
    [Authorities.ADMIN, Authorities.SPORTELLISTA, Authorities.OPERATORE_COMUNE],
    currentUser
  );

  const isSportellistaOrProfessionista = checkRequiredAuthorities(
    [Authorities.SPORTELLISTA, Authorities.PROFESSIONISTA],
    currentUser
  );

  const isUser = checkRequiredAuthorities(
    [Authorities.USER],
    currentUser
  );


  // useEffect(() => {
  //   if (i18n.language !== lang) {
  //     console.log('CHANGING', lang, i18n.language);
  //     i18n.changeLanguage(lang);
  //   }
  // }, [lang]);

  const handleLanguageSelect = (lng: string) => {
    i18n.changeLanguage(lng, () => dispatch(changeUserLanguage(lng)));
  };

  const handleMenuToggle = () => {
    if (initialCollapsed) setCollapsed(!collapsed);
  };

  return (
    <>
      <Navbar
        light
        expand="lg"
        style={{ position: 'sticky', top: 0, zIndex: 100, background: '#fffffff2', justifyContent: 'space-around'  }}
        container="lg"
        className="border-gradient"
      >
        <NavbarBrand  onClick={() => {
                  navigate('/');
                }}>
          <img
            src="/logo-agenzia-sociale-casa-bari-300x271.png"
            alt="Agenzia sociale casa bari"
          />
        </NavbarBrand>
        {isAdministratorOrAssistente && (
          <NavbarToggler
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
        )}
        {isAdministratorOrAssistente && (
          <Collapse isOpen={collapsed} navbar>
            <FontAwesomeIcon
              className="x-to-close"
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              icon={faXmark}
            />
            <AppMenu onMenuItemClick={handleMenuToggle} />
            <Badge color="secondary">{currentUser?.authorities.join(' ')}</Badge>
          </Collapse>
        )}

        {!accessToken && isAdministratorOrAssistente && (
          <UncontrolledDropdown inNavbar>
            <DropdownToggle color="link">
              <FontAwesomeIcon icon={faUser} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => navigate('/user/login')}>
                {t('general.actions.login')}
              </DropdownItem>
              <DropdownItem onClick={() => navigate('/user/register')}>
                {t('general.actions.register')}
              </DropdownItem>
              <DropdownItem onClick={() => navigate('/user/forgot-password')}>
                {t('user.forgot_password.title')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        <UncontrolledDropdown inNavbar>
          <DropdownToggle nav caret color="primary">
            {lang.toUpperCase()}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleLanguageSelect('en')}>
              EN
            </DropdownItem>
            <DropdownItem onClick={() => handleLanguageSelect('it')}>
              IT
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        {accessToken && (isAdministratorOrAssistente || isSportellistaOrProfessionista || isUser) && (
          <UncontrolledDropdown direction="right" inNavbar>
            <DropdownToggle color="link">
              <Avatar
                name={currentUser?.firstName + ' ' + currentUser?.lastName}
                size="30"
                color={'#d9534f'} 
              />
            </DropdownToggle>

            <DropdownMenu right>
              <DropdownItem
                onClick={() => {
                  navigate('/user/'+ currentUser?.id);
                }}
              >
                {t('general.buttons.profile')}
              </DropdownItem>
            {isSportellistaOrProfessionista && <DropdownItem
                onClick={() => {
                  navigate('/user/badge-in-out');
                }}
              >
                {t('general.buttons.badge')}
              </DropdownItem> }

              <DropdownItem
                onClick={() => {
                  dispatch(logout());
                  navigate('/');
                }}
              >
                {t('general.actions.logout')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </Navbar>
      <hr />
    </>
  );
}
