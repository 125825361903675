import { withTheme } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { t } from "i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { Wizard } from "../../components/wizard";

import {
  faChevronLeft,
  faChevronRight,
  faEye,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import { Theme as Bootstrap4Theme } from "@rjsf/bootstrap-4";
import {
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  ArrayFieldTemplate,
  FieldTemplate,
  ObjectFieldTemplate,
} from "../../components/TemplateFieldShifter";
import MobileButton from "../../components/mobile-button";
import { userLngSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import { tradKey } from "./survey-template-edit.page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const FormWithBootstrap = withTheme(Bootstrap4Theme);

export interface SurveyTemplateStaticProps {
  jsonSchema: any;
  uiSchema: any;
  tradSchema?: any;
  isTradEnabled: boolean;
  formDatas: any;
  onSubmit: any;
  isReadMode: boolean;
}

export default function SurveyTemplateStatic(props: SurveyTemplateStaticProps) {
  let {
    jsonSchema,
    uiSchema,
    tradSchema,
    isTradEnabled,
    formDatas,
    onSubmit,
    isReadMode,
  } = props;
  const [step, setStep] = useState<any>(0);
  const initialData = formDatas ? formDatas : {};
  const [datas, setDatas] = useState<any>(initialData);
  const maxstep = Array.isArray(jsonSchema) ? jsonSchema.length : 1;

  // global Language
  const lang = useAppSelector(userLngSelector);
  const [maxValidStep, setMaxValidStep] = useState<number>(0);

  // local Language
  const [staticLang, setStaticLang] = useState<string>(lang);

  const formRef = useRef<any>(null);

  const changeLang = (l: string) => {
    setStaticLang(l);
  };

  const onFormSubmit = ({ formData }: any) => {
    onSubmit(formData);
  };

  const formsT = useMemo(() => {
    let language = isTradEnabled ? staticLang : lang;

    let tradForms = tradKey(jsonSchema, tradSchema, language);
    return tradForms;
  }, [isTradEnabled, lang, staticLang, tradSchema, uiSchema]);

  const uisT = useMemo(() => {
    let language = isTradEnabled ? staticLang : lang;
    let tradForms = tradKey(uiSchema, tradSchema, language);
    return tradForms;
  }, [isTradEnabled, lang, staticLang, tradSchema, uiSchema]);

  const templates = {
    FieldTemplate,
    ArrayFieldTemplate,
    ObjectFieldTemplate,
  };

  const saveDataNext = (ev: any) => {
    ev.preventDefault();
    let err = !isReadMode ? formRef.current.validateForm() : {};

    if (err) setStep(step + 1);
  };
  const saveDataPrev = (ev: any) => {
    ev.preventDefault();
    let err = !isReadMode ? formRef.current.validateForm() : {};

    if (err) setStep(step - 1);
  };

  const onChange = (changes: any) => {
    setDatas(changes.formData);
  };

  const transformErrors = (errors: any) => {
    return errors.map((error: any) => {
      if (error.name === "required") {
        error.message = error.message.replace(
          "must have required property",
          "La proprietà è richiesta"
        );
      }
      if (error.name === "minLength") {
        error.message = `Deve avere almeno ${error.params.limit} caratteri`;
      }
      if (error.name === "minItems") {
        error.message = `Deve avere almeno ${error.params.limit} elementi`;
      }
      if (error.name === "format" && error.params.format === "email") {
        error.message = "Deve essere un indirizzo email valido";
      }
      return error;
    });
  };

  useEffect(() => {
    setDatas(initialData);
  }, [initialData]);

  // console.log("renderrrrr", formsT, uisT, datas)

  return (
    <div className="container mt-2">
      {isTradEnabled && (
        <div className="card">
          <div className="card-body">
            Testa la lingua:{" "}
            <UncontrolledDropdown inNavbar>
              <DropdownToggle nav caret color="primary">
                {staticLang.toUpperCase()}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => changeLang("en")}>EN</DropdownItem>
                <DropdownItem onClick={() => changeLang("it")}>IT</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      )}
      {Array.isArray(jsonSchema) && jsonSchema.length > 0 && (
        <div className="mt-2">
          {jsonSchema.length > 1 && (
            <Wizard
              initialStep={step}
              titles={jsonSchema.map((el: any) => el.title)}
              onStepClick={(index: any) => {
                if (isReadMode) {
                  setStep(index);
                } else {
                  let valid = formRef.current.validateForm();
                  if (!valid) {
                    if (index <= maxValidStep) setStep(index);
                    // setMaxValidStep(Math.max(step, maxValidStep));
                  } else {
                    if (index <= maxValidStep + 1) {
                      setStep(index);
                      setMaxValidStep(Math.max(index, maxValidStep));
                    }
                  }
                }
              }}
            />
          )}
          <div className="card">
            <div className="card-body">
              <FormWithBootstrap
                ref={formRef}
                templates={templates}
                onSubmit={onFormSubmit}
                onChange={onChange}
                formData={datas}
                schema={formsT[step]}
                uiSchema={uisT[step]}
                validator={validator}
                transformErrors={transformErrors}
                showErrorList={false}
                readonly={isReadMode}
                focusOnFirstError={true}
              >
                <div>
                  <ButtonGroup
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "2rem",
                      width: "100%",
                    }}
                  >
                    {jsonSchema.length > 1 && (
                      <MobileButton
                        color={"primary"}
                        size="lg"
                        outline
                        type="submit"
                        onClick={saveDataPrev}
                        disabled={step === 0}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />{" "}
                        {t("general.buttons.back")}
                      </MobileButton>
                    )}
                    {!isReadMode && (
                      <MobileButton
                        icon={faFloppyDisk}
                        color={"primary"}
                        size="lg"
                        style={{ flexGrow: 1 }}
                        type="submit"
                        text={t("general.buttons.send")}
                        disabled={step !== maxstep - 1}
                      />
                    )}
                    {jsonSchema.length > 1 && (
                      <MobileButton
                        size="lg"
                        color={"primary"}
                        onClick={saveDataNext}
                        outline
                        type="submit"
                        disabled={step === maxstep - 1}
                      >
                        {t("general.buttons.forward")}{" "}
                        <FontAwesomeIcon icon={faChevronRight} />
                      </MobileButton>
                    )}
                  </ButtonGroup>
                </div>
              </FormWithBootstrap>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
