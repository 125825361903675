import { Axios, AxiosRequestConfig } from "axios";
import {
  CreateSurveyAnswerDocument,
  DetailSurveyAnswerDocument,
} from "../model/survey-answer-document.model";
import { defaultHeaders } from "./common";

export const getSurveyAnswerDocumentListConf = (query: string) => {
  return {
    method: "get",
    url: "/api/survey-answer-document" + query,
    headers: defaultHeaders,
  };
};

export const getDetailAnswerDocumentConf = (id: string) => {
  return {
    method: "get",
    maxBodyLength: Infinity,
    url: `/api/survey-answer-document/${id}`,
    headers: defaultHeaders,
  };
};

export const updateSurveyAnswerDocumentConf = (
  answerDocument: DetailSurveyAnswerDocument
) => {
  return {
    method: "put",
    maxBodyLength: Infinity,
    url: `/api/survey-answer-document/${answerDocument.id}`,
    headers: defaultHeaders,
    data: answerDocument,
  };
};

export const createSurveyAnswerDocumentConf = (
  answerDocument: CreateSurveyAnswerDocument
): AxiosRequestConfig => {
  const { surveyAnswerId, notes, title, file } = answerDocument;
  const form = new FormData();
  form.append("surveyAnswerId", surveyAnswerId.toString());
  file && form.append("file", file);
  form.append("title", title);
  form.append("notes", notes);
  console.log("form", form);

  return {
    method: "POST",
    url: "/api/survey-answer-document",
    headers: {
      "content-type": "multipart/form-data",
    },
    data: form,
  };
};
export const deleteSurveyAnswerDocumentConf = (id: string) => {
  return {
    method: "delete",
    maxBodyLength: Infinity,
    url: `/api/survey-answer-document/` + id,
    headers: defaultHeaders,
  };
};
