import { t } from 'i18next';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export interface PaginationState {
  page: number;
  pageSize: number;
  totalElements: number | null;
}
interface FacilityViewProps {
  showPaginationInput?: boolean;
  page: number;
  pageSize: number;
  totalElements: number;
  onChange: (num: number) => void;
  onSizeChange: (num: number) => void;
}
const PaginationUtility = (props: FacilityViewProps) => {
  const { page, pageSize, totalElements, onChange } = props;
  let pages = Math.ceil(totalElements / pageSize);
  let p = [];

  if (page > 2 && pages > 2 && page === pages) {
    let prev = page - 2;
    p.push(
      <PaginationItem key={'element' + prev * pageSize} active={prev == page}>
        <PaginationLink
          onClick={() => {
            onChange(prev);
          }}
        >
          {prev}
        </PaginationLink>
      </PaginationItem>
    );
  }
  if (page > 1) {
    let prev = page - 1;
    p.push(
      <PaginationItem key={'element' + prev * pageSize} active={prev == page}>
        <PaginationLink
          onClick={() => {
            onChange(prev);
          }}
        >
          {prev}
        </PaginationLink>
      </PaginationItem>
    );
  }

  p.push(
    <PaginationItem key={'element' + page * pageSize} active={true}>
      <PaginationLink
        onClick={() => {
          onChange(page);
        }}
      >
        {page}
      </PaginationLink>
    </PaginationItem>
  );
  if (page < pages) {
    let next = page + 1;
    p.push(
      <PaginationItem key={'element' + next * pageSize} active={next == page}>
        <PaginationLink
          onClick={() => {
            onChange(next);
          }}
        >
          {next}
        </PaginationLink>
      </PaginationItem>
    );
  }
  if (page + 1 < pages && 1 === page) {
    let next = page + 2;
    p.push(
      <PaginationItem key={'element' + next * pageSize} active={next == page}>
        <PaginationLink
          onClick={() => {
            onChange(next);
          }}
        >
          {next}
        </PaginationLink>
      </PaginationItem>
    );
  }

  return (
    <>
      {totalElements > pageSize && (
        <div className="d-flex justify-content-between">
          {totalElements > 0 && (
            <div>
              <Pagination size="md" style={{ marginBottom: 0 }}>
                <PaginationItem>
                  <PaginationLink
                    first
                    onClick={() => {
                      onChange(1);
                    }}
                  >
                    « 1
                  </PaginationLink>
                </PaginationItem>
                {p}
                <PaginationItem>
                  <PaginationLink
                    last
                    onClick={() => {
                      onChange(pages);
                    }}
                  >
                    {Math.ceil(totalElements / pageSize)} »
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          )}
          {totalElements > 0 && (
            <div className="rows_count">
              {t('general.pagination', {
                start: (page - 1) * pageSize == 0 ? 1 : (page - 1) * pageSize,
                end:
                  page * pageSize > totalElements
                    ? totalElements
                    : page * pageSize,
                total: totalElements,
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PaginationUtility;
