import {
  faAddressBook,
  faCalendarDays,
  faEye,
  faListCheck,
  faPencil,
  faPlus,
  faRefresh,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
  Table,
} from "reactstrap";
import { ErrorsView } from "../../components/errors.view";
import MobileButton from "../../components/mobile-button";
import PaginationUtility from "../../components/pagination";
import useRequest from "../../hook/use-request.hook";
import { useAppUtilContext } from "../../util/app-util.context";
import {
  getAppointmentListAllConf,
  getAppointmentListConf,
} from "../../api/appointmentAPI";
import Calendar from "../../components/calendar";
import { getUserListConf } from "../../api/userAPI";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../redux/store";
import {
  Authorities,
  currentUserSelector,
} from "../../redux/reducers/userSlice";
import { checkRequiredAuthorities } from "../../components/authority-boundary-view";
import { formattaData, formattaReadableData, formattaReadableDataMonth, getDateYYYYMMDD, templateTableEditClass, templateTableShowClass } from "../../util/form.util";


const registerRs = (register: any, fieldName: string, options = {}) => {
  const registeredField: Partial<any> = register(fieldName, options);
  const ref = registeredField.ref;
  delete registeredField.ref;
  return { ...registeredField, innerRef: ref };
};

export default function HomeAppointmentListPage(props: any) {
  const { fixedUser, monthView, withForm, fullCalendar } = props;
  const currentUser = useAppSelector(currentUserSelector);
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<any[]>([]);
  const [monthList, setMonthList] = useState<any[]>([]);
  const [month, setMonth] = useState<any>();
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [usersList, setUsersList] = useState<any>(null);

  const isOperatore = currentUser?.authorityChecks.isOperatore 
  const isAdministrator = currentUser?.authorityChecks.isAdministrator 
  const isSportellista = currentUser?.authorityChecks.isSportellista 
  const isProfessionista = currentUser?.authorityChecks.isProfessionista

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 20,
    totalElements: null,
  });

  
  const onErrors = (errors: any) => {
    showModal(
      t("modal.request_errors"),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const refreshFirstPage = () => {
    setPagination({
      page: 1,
      pageSize: 20,
      totalElements: null,
    });

    refresh(pagination);
  };

  useEffect(() => {
    let oggi = new Date();
    setMonth(oggi);

    if (monthView) {
      let primoGiorno = new Date(oggi.getFullYear(), oggi.getMonth(), 1);
      let ultimoGiorno = new Date(oggi.getFullYear(), oggi.getMonth() + 1, 0);

      setFromDate(primoGiorno);
      setToDate(ultimoGiorno);
    } else {
      setFromDate(oggi);
    }

    if (fixedUser) {
      setUser(fixedUser);
    }

    if(withForm)
      fetchData(
        getUserListConf(1, 1000, ""),
        (data: any, header: any) => {
          setUsersList(data);
        },
        onErrors
      );
  }, []);

  useEffect(() => {
    setValue("fromDate", getDateYYYYMMDD(fromDate));
    setValue("toDate", getDateYYYYMMDD(toDate));

    refreshFirstPage();
  }, [fromDate, toDate]);

  const refresh = (pag: any) => {
    let queryGen = "";
    let queryList = "";
    if (user) queryGen = queryGen + "&userId=" + user;

    if (fromDate && toDate) {
      queryList =
        queryList +
        "&fromDate=" +
        formattaData(fromDate) +
        "&toDate=" +
        formattaData(toDate);
    } else if (fromDate) {
      let domani = new Date(
        fromDate.getFullYear(),
        fromDate.getMonth(),
        fromDate.getDate() + 1
      );

      queryList =
        queryList +
        "&fromDate=" +
        formattaData(fromDate) +
        "&toDate=" +
        formattaData(domani);
    }

    if (fromDate) {
      fetchData(
        getAppointmentListConf(pag.page, pag.pageSize, queryGen + queryList),
        (data: any, header: any) => {
          setList(
            data.map((el: any) => {
              const inputDate = new Date(el.date);

              return {
                ...el,
                id: el.id,
                title: el.title ? el.title : "",
                start: el.date,
                startStr: inputDate.toISOString(),
              };
            })
          );
          setPagination({
            ...pag,
            ...{ totalElements: header["x-total-count"] },
          });
        },
        onErrors
      );
    }
  };

  useEffect(() => {
    setValue("user", user);

    refreshFirstPage()
    refreshCalendar()
  }, [user]);

  useEffect(() => {
    if (monthView && month) {
      let primoGiorno = new Date(month.getFullYear(), month.getMonth(), 1);
      let ultimoGiorno = new Date(month.getFullYear(), month.getMonth() + 1, 0);

      setFromDate(primoGiorno);
      setToDate(ultimoGiorno);
    }

    refreshCalendar();
  }, [month]);

  const refreshCalendar = () => {
    let queryMonth = "";

    if (month) {
      let dataCalendario = new Date(month);

      let primoGiorno = new Date(
        dataCalendario.getFullYear(),
        dataCalendario.getMonth(),
        1
      );
      let ultimoGiorno = new Date(
        dataCalendario.getFullYear(),
        dataCalendario.getMonth() + 1,
        0
      );

      queryMonth =
        "fromDate=" +
        formattaData(primoGiorno) +
        "&toDate=" +
        formattaData(ultimoGiorno);
    }

    if (user) queryMonth = queryMonth + "&userId=" + user;

    fetchData(
      getAppointmentListAllConf(queryMonth),
      (data: any, header: any) => {
        // console.log("calendar", header )
        setMonthList(
          data.map((el: any) => {
            const inputDate = new Date(el.date);

            return {
              ...el,
              id: el.id,
              title:
                (el.title ? el.title : "") +
                (el.professionista
                  ? " - " +
                    el.professionista.firstName +
                    " " +
                    el.professionista.lastName
                  : "") +
                (el.user
                  ? " - " + el.user.firstName + " " + el.user.lastName
                  : ""),
              start: el.date,
              startStr: inputDate.toISOString(),
            };
          })
        );
      },
      onErrors
    );
  };

  const handleMonthSet = (dateInfo: any) => {
    setMonth(dateInfo);
  };

  const handleDatesSet = (dateInfo: any) => {
    setFromDate(dateInfo);
    setToDate(null);
  };

  const onSubmit = (data: any) => {
    if (data.user == "0") setUser(null);
    else if (user !== data.user) setUser(data.user);

    if (data.fromDate) setFromDate(new Date(data.fromDate));
    if (data.toDate) setToDate(new Date(data.toDate));

    refreshFirstPage();
  };

  const { register, handleSubmit, setValue } = useForm();

  return (
    <>
        <div className="row">
          <div className="col" style={{ flexGrow: "2" }}>
            <div>
              <div
                className="justify-content-between pt-3"
                style={{   marginBottom: '20px', display: "flex", gap: "10px" }}
              >
                <h2 style={{ width: '100%',   margin: 0 }} className="page-title">
                { !withForm && !fixedUser && <FontAwesomeIcon size="2x" icon={faCalendarDays} />}{" "}
                  {fromDate && !monthView
                    ? t("home.appointment", formattaReadableData(fromDate, t))
                    : ""}
                  {monthView && fromDate && toDate
                    ? t(
                        "home.appointmentMonth",
                        formattaReadableDataMonth(fromDate, toDate, t)
                      )
                    : ""}
                  {monthView && fromDate && toDate == null
                    ? t("home.appointment", formattaReadableData(fromDate, t))
                    : ""}

{ !withForm && <div style={{float: 'right' }}>
 {!isOperatore && ( isAdministrator || isSportellista || isProfessionista) && <MobileButton
                    color="secondary"
                    outline
                    onClick={() => navigate(`/appointment/create`)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="d-none d-md-inline">
                      &nbsp; {t("general.buttons.create")}
                    </span>
                  </MobileButton>}{" "}
                  <MobileButton
                    onClick={() => refresh(pagination)}
                    disabled={loading}
                    color="secondary"
                    outline
                  >
                    {!loading ? (
                      <>
                        <FontAwesomeIcon icon={faRefresh} />
                      </>
                    ) : (
                      <>
                        {/* <Spinner size="sm">Loading...</Spinner> */}
                        <svg viewBox="25 25 50 50" className='svgcircle d-none d-md-inline mt-2'>
                          <circle r="20" cy="50" cx="50"></circle>
                        </svg>
                      </>
                    )}
                  </MobileButton>
{" "}
                 {!fixedUser && <MobileButton
                    color="secondary"
                    onClick={() => navigate(`/appointment`)}
                  >
                    <FontAwesomeIcon icon={faListCheck} />
                    <span className="d-none d-md-inline">
                      &nbsp; {t("home.appuntamenti-all")}
                    </span>
                  </MobileButton>}
                </div> }

                </h2>
              
              </div>

              {withForm && (
                <>
                  <div className="filter-card card mt-3">
                    <div className="card-body">
                      <h6 className="mt-1 mb-3">
                        {t("general.actions.filter")}
                      </h6>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mt-2">
                          {fixedUser == undefined && (
                            <div className="row">
                              <div className="col-lg-8 col-md-8 col-sm-8">
                                <FormGroup className="form-field">
                                  <Label
                                    className="form-label select"
                                    for="user"
                                  >
                                    {t("appointment.list.user")}
                                  </Label>
                                  <Input
                                    id="user"
                                    name="user"
                                    type="select"
                                    {...registerRs(register, "user", {})}
                                  >
                                    <option key={0} value={0}>
                                      Nessuno
                                    </option>
                                    {usersList &&
                                      usersList.map((it: any) => {
                                        return (
                                          <option
                                            key={it.id}
                                            value={it.id + ""}
                                          >
                                            {it.id} - {it.firstName}{" "}
                                            {it.lastName}
                                          </option>
                                        );
                                      })}
                                  </Input>
                                </FormGroup>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                              <FormGroup>
                                <div
                                  style={{
                                    marginBottom: ".5rem",
                                    display: "inline-block",
                                  }}
                                >
                                  {t("general.date.from")}
                                </div>
                                <Input
                                  id="fromDate"
                                  name="fromDate"
                                  type="date"
                                  {...registerRs(register, "fromDate", {})}
                                />
                              </FormGroup>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                              <FormGroup>
                                <div
                                  style={{
                                    marginBottom: ".5rem",
                                    display: "inline-block",
                                  }}
                                >
                                  {t("general.date.to")}
                                </div>
                                <Input
                                  id="toDate"
                                  name="toDate"
                                  type="date"
                                  {...registerRs(register, "toDate", {})}
                                />
                              </FormGroup>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-2">
                              <div style={{ marginBottom: ".5rem" }}>
                                &nbsp;
                              </div>
                              <Button color="primary" type="submit">
                                <FontAwesomeIcon icon={faSearch} />{" "}
                                {t("general.actions.search")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </>
              )}

              {fullCalendar && (
                <div style={{ marginTop: "1rem" }}>
                  {" "}
                  <Calendar
                    data={monthList}
                    isCompressed={false}
                    updateMonth={handleMonthSet}
                    updateDate={handleDatesSet}
                  />
                </div>
              )}

              <div className="mt-2">

              { withForm && <div style={{float: 'right' }}>
 {!isOperatore &&  <MobileButton
                    color="secondary"
                    outline
                    onClick={() => navigate(`/appointment/create`)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="d-none d-md-inline">
                      &nbsp; {t("general.buttons.create")}
                    </span>
                  </MobileButton>}{" "}
                  <MobileButton
                    onClick={() => refresh(pagination)}
                    disabled={loading}
                    color="secondary"
                    outline
                  >
                    {!loading ? (
                      <>
                        <FontAwesomeIcon icon={faRefresh} />
                      </>
                    ) : (
                      <>
                        {/* <Spinner size="sm">Loading...</Spinner> */}
                        <svg viewBox="25 25 50 50" className='svgcircle d-none d-md-inline mt-2'>
                         <circle r="20" cy="50" cx="50"></circle>
                        </svg>
                      </>
                    )}
                  </MobileButton>
                </div> }

                <Table className="mt-2" striped responsive>
                  <thead>
                    <tr style={{ whiteSpace: "break-spaces" }}>
                      <th>{t("general.id")}</th>
                      <th>{t("appointment.title")}</th>
                      <th>{t("appointment.professionista")}</th>
                      <th>{t("appointment.user")}</th>
                      <th>{t("appointment.date")}</th>
                      {!isOperatore && <th style={{ width: "150px" }}></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.totalElements == 0 && (
                      <tr>
                        <td colSpan={6}>
                          {t("general.errors.no_items_found")}
                        </td>
                      </tr>
                    )}
                    {pagination.totalElements > 0 &&
                      list.map((it, idx) => {
                        return (
                          <tr key={it.id}>
                            <td style={{ width: "auto" }}>{it.id}</td>
                            <td style={{ width: "auto" }}>{it.title}</td>
                            <td style={{ width: "auto" }}>
                             <a href="" onClick={() => navigate(`/user/${it.professionista.id}`)} > 
                  {it.professionista?.firstName}{" "}
                              {it.professionista?.lastName}</a>
                            </td>
                            <td style={{ width: "auto" }}>
                            <a href="" onClick={() => navigate(`/user/${it.user.id}`)} >
                              {it.user?.firstName} {it.user?.lastName}</a>
                            </td>
                            <td style={{ width: "auto" }}>{it.date}</td>
                            {!isOperatore && (
                              <td style={{ width: "auto" }}>
                                <ButtonGroup style={{ width: "120px" }}>
                                  <MobileButton
                                    inTable={true}
                                    icon={faEye}
                                    className="d-block"
                                    style={{ width: "100%" }}
                                    color={templateTableShowClass}
                                    size="sm"
                                    id={"view" + it.id}
                                    onClick={() =>
                                      navigate(`/appointment/${it.id}`)
                                    }
                                  />
                                  <MobileButton
                                    inTable={true}
                                    icon={faPencil}
                                    className="d-block"
                                    style={{ width: "100%" }}
                                    color={templateTableEditClass}
                                    size="sm"
                                    id={"edit" + it.id}
                                    onClick={() =>
                                      navigate(`/appointment/${it.id}/edit`)
                                    }
                                  />
                                </ButtonGroup>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>

              <div className="pb-1">
                <PaginationUtility
                  {...pagination}
                  onSizeChange={(n: any) => {
                    if (pagination.pageSize !== n)
                      refresh({ ...pagination, ...{ page: 1, pageSize: n } });
                  }}
                  onChange={(n: any) => {
                    if (pagination.page !== n)
                      refresh({ ...pagination, ...{ page: n } });
                  }}
                />
              </div>
            </div>
          </div>
          {!fullCalendar && (
            <div
              className="col"
              style={{
                flexGrow: "0",
                minWidth: "430px",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Calendar
                data={monthList}
                isCompressed={true}
                updateMonth={handleMonthSet}
                updateDate={handleDatesSet}
              />
            </div>
          )}
        </div>
    </>
  );
}
