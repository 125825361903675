import {
  CreateSurveyAnswer,
  EditSurveyAnswer,
} from '../model/survey-answer.model';
import { defaultHeaders } from './common';

import { AxiosRequestConfig } from 'axios';
export const getArchievedSurveyAnswerListConf = (
  page: number,
  size: number,
  query: string,
  id?: string
) => {
  return {
    method: 'get',
    url: '/api/archived-survey-answer?page=' + page + '&size=' + size + query + '&surveyAnswerId=' + id,
    headers: defaultHeaders,
  };
};

export const getDetailArchivedSurveyAnswerConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/archived-survey-answer/${id}`,
    headers: defaultHeaders,
  };
};
export const getPrintSurveyAnswerConf = (id: string): AxiosRequestConfig => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/survey-answer/${id}/print`,
    headers: defaultHeaders,
    responseType: 'arraybuffer',
  };
};

export const createDetailSurveyAnswerConf = (
  surveyAnswer: CreateSurveyAnswer
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: `/api/survey-answer`,
    headers: defaultHeaders,
    data: surveyAnswer,
  };
};
export const updateDetailSurveyAnswerConf = (
  surveyAnswer: EditSurveyAnswer
) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: `/api/survey-answer/${surveyAnswer.id}`,
    headers: defaultHeaders,
    data: JSON.stringify(surveyAnswer),
  };
};

export const sendCodeSurveyAnswerConf = (
  id: string,
  phoneNumber: any,
  fiscalCode: any,
  email: any
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: `/api/survey-answer/${id}/send-code`,
    headers: defaultHeaders,
    data: {
      phoneNumber,
      fiscalCode,
      email,
    },
  };
};
export const confirmCodeSurveyAnswerConf = (id: number, code: string) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: `/api/survey-answer/verify-code`,
    headers: defaultHeaders,
    data: {
      answerId: id,
      code: code,
    },
  };
};
