import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useState } from "react";
import { Button } from "reactstrap";
import { dataURLtoFile } from "./file.util";
const FileWidget = forwardRef((props: any, ref) => {
  const { id, value, required, onChange, schema, formData, onFileChange } =
    props;
  // let fileTemp = null;

  // if (value) {
  //   fileTemp = dataURLtoFile(value);
  // }

  // const [fileUrl, setFileUrl] = useState(value || "");
  // const [file, setFile] = useState<any>(fileTemp);

  const handleFileChange = (event: any) => {
    console.log("handleFileChange", event);
    const file = event.target.files[0];
    onFileChange(file);
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64File = reader.result;
        let base64 = null;
        let arr = base64File?.toString().split(";");
        if (arr && arr.length > 1) {
          base64 = `${arr[0]};name=${file.name};${arr[1]}`;
        }
        // setFileUrl(base64);
        onChange(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = () => {
    // if (fileUrl) {
    //   const a = document.createElement("a");
    //   a.href = fileUrl;
    //   a.download = file.name; // Customize file name as needed
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    // }
  };

  return (
    <div>
      <input
        type="file"
        id={id}
        onChange={handleFileChange}
        required={required}
      />
      {/* {fileUrl && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginTop: "10px",
          }}
        >
          <Button onClick={handleDownload} color="primary">
            <FontAwesomeIcon icon={faDownload} color="white" />
          </Button>
          <div style={{ marginLeft: "10px" }}>
            {file ? file.name : "unknownFile"}
            {file ? file.sie : "unknownSize"}
          </div>
        </div>
      )} */}
    </div>
  );
});

export default FileWidget;
