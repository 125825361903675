import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppUtilContext } from "../../util/app-util.context";
import { FormWithBootstrap, templates } from "../../util/form-util";

import {
  faEye,
  faPencil,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Table } from "reactstrap";
import {
  createSurveyAnswerDocumentConf,
  deleteSurveyAnswerDocumentConf,
  getSurveyAnswerDocumentListConf,
  updateSurveyAnswerDocumentConf,
} from "../../api/answerDocumentAPI";
import MobileButton from "../../components/mobile-button";
import useRequest from "../../hook/use-request.hook";
import FileWidget from "../../util/file-widget";
import {
  templateTableEditClass,
  templateTableShowClass,
} from "../../util/form.util";
import {
  CreateSurveyAnswerDocument,
  DetailSurveyAnswerDocument,
  UpdateSurveyAnswerDocument,
} from "../../model/survey-answer-document.model";
import DownloadButton from "../../components/DownloadButton";
export default function AddAnswerDocument() {
  let { id } = useParams();
  const formRef = useRef<any>(null);
  const [surveyAnswerDocuments, setSurveyAnswerDocuments] = useState<
    DetailSurveyAnswerDocument[]
  >([]);
  const { loading, fetchData } = useRequest<any>();
  const [formData, setFormData] = useState<any>();
  console.log("formData", formData);
  const [showProg, setShowProg] = useState<any>({ display: false, body: { progress: 0 } });
  const currentFileRef = useRef<File>();
  console.log(showProg, "PROGRESS");
  const { t } = useTranslation();
  useEffect(() => {
    if (id) {
      fetchData(
        getSurveyAnswerDocumentListConf("?surveyAnswerId=" + id),
        onSuccessAnswerDocumentList,
        onErrorsModal
      );
    }
  }, [id]);

  const { onErrorsModal, showModal, closeModal } = useAppUtilContext();

  const onSuccessAnswerDocumentList = (dtos: DetailSurveyAnswerDocument[]) => {
    console.log("dtos", dtos);
    setSurveyAnswerDocuments(dtos);
    // closeModal();
  };

  const editSuccessAnswerDocument = (editId: number) => {
    console.log("editSuccessAnswerDocument", editId);
    const data = surveyAnswerDocuments.find((el: any) => el.id === editId);
    let sad = {};
    if (data) {
      sad = {
        id: data.id,
        title: data.title,
        notes: data.notes,
        surveyAnswerId: id ? parseInt(id) : undefined,
      };
    }
    setFormData(sad);
  };
  const createSuccessAnswerDocument = () => {
    setFormData({ surveyAnswerId: parseInt(id ? id : "0") });
  };

  const onChange = (props: any) => {
    setFormData(props.formData);
  };

  const onSubmit = ({ formData }: any) => {
    closeModal();
    // showModal(
    //   "Stiamo caricando i documenti",
    //   <>
    //   "sarai indirizzato alla pagina non appena l'operazione sarà conclusa"
    //   <Progress animated color="success" width={200} min={0} max={100} value={uploadProg.progress *100} />
    //   </>,
    //  <></>,
    //   <svg viewBox="25 25 50 50" className="svgcircle d-none d-md-inline mt-2">
    //     <circle r="20" cy="50" cx="50"></circle>
    //   </svg>

    // );

    if (formData.id) {
      onSurveyAnswerDocumentEdit({ ...formData });
    } else {
      onSurveyAnswerDocumentCreate({
        ...formData,
        file: currentFileRef.current,
      });
    }
  };

  const onSuccess = (props: any) => {
    fetchData(
      getSurveyAnswerDocumentListConf("?surveyAnswerId=" + id),
      onSuccessAnswerDocumentList,
      onErrorsModal
    );
  };

  const onFileChange = (file: File) => {
    currentFileRef.current = file;
  };

  useEffect(() => {
    if (formData) {
      console.log("formData", formData);
      const schema: RJSFSchema = !formData.id
        ? {
            title: "",
            type: "object",
            properties: {
              id: {
                type: "number",
                title: t("general.id"),
                readOnly: true,
              },
              surveyAnswerId: {
                type: "number",
                title: t("survey-answer.document.surveyAnswerId"),
                readOnly: true,
              },
              title: {
                type: "string",
                title: t("survey-answer.document.title"),
              },
              notes: {
                type: "string",
                title: t("survey-answer.document.notes"),
              },
              file: {
                type: "string",
                title: "File Upload",
                format: "data-url",
              },
            },
            required: ["file", "title"],
          }
        : {
            title: "",
            type: "object",
            properties: {
              id: {
                type: "number",
                title: t("general.id"),
                readOnly: true,
              },
              surveyAnswerId: {
                type: "number",
                title: t("survey-answer.document.surveyAnswerId"),
                readOnly: true,
              },
              title: {
                type: "string",
                title: t("survey-answer.document.title"),
              },
              notes: {
                type: "string",
                title: t("survey-answer.document.notes"),
              },
            },
            required: ["title"],
          };
      const uiSchema = {
        id: { "ui:widget": "hidden" },
        surveyAnswerId: { "ui:widget": "hidden" },
      };

      const widgets = {
        "data-url": (props: any) => {
          return (
            <FileWidget {...props} onFileChange={onFileChange}></FileWidget>
          );
        },
      };

      showModal(
        formData.id
          ? t("survey-answer.document.edit")
          : t("survey-answer.document.add"),
        <>
          <FormWithBootstrap
            formData={formData}
            ref={formRef}
            schema={schema}
            uiSchema={uiSchema}
            onChange={onChange}
            onSubmit={onSubmit}
            templates={templates}
            widgets={widgets}
            validator={validator}
            children={true}
          />{" "}
        </>,
        null,
        <MobileButton
          inTable={true}
          icon={faSave}
          className="d-block"
          style={{ width: "100%" }}
          color={"primary"}
          text={t("general.buttons.send")}
          onClick={() => {
            const err = formRef?.current.validateForm();

            if (err) formRef?.current.submit();
          }}
        />
      );
    }
  }, [formData]);

  const onSurveyAnswerDocumentCreate = (formData: { surveyAnswerId: number; notes: string; title: string; file?: File }) => {
    let conf = createSurveyAnswerDocumentConf(formData);
    conf.onUploadProgress = (e: any) => {
      setShowProg({ display: true, body: e });
      if (e.progress === 1) {
        setShowProg({ display: false, body: { progress: 0 } });
        closeModal();
      }
      console.log("onUploadProgress", e);
    };
    fetchData(conf, onSuccess, onErrorsModal);
  };
  const onSurveyAnswerDocumentEdit = (formData: any) => {
    fetchData(
      updateSurveyAnswerDocumentConf(formData),
      onSuccess,
      onErrorsModal
    );
  };

  const onSurveyAnswerDocumentDelete = (editId: any) => {
    fetchData(deleteSurveyAnswerDocumentConf(editId), onSuccess, onErrorsModal);
  };

  return (
    <>
      <div className="header-container">
        <h2 className="page-title"> {t("survey-answer.document.doctitle")}</h2>
        <Button
          onClick={createSuccessAnswerDocument}
          color={templateTableEditClass}
          style={{ float: "right", marginTop: "5px" }}
        >
          <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faPlus} />{" "}
          {t("survey-answer.document.add")}
        </Button>
      </div>
      <Table striped responsive>
        <thead>
          <tr style={{ whiteSpace: "break-spaces" }}>
            <th style={{ width: "20%" }}>
              {" "}
              {t("survey-answer.document.title")}
            </th>
            <th style={{ width: "20%" }}>
              {" "}
              {t("survey-answer.document.notes")}
            </th>
            <th style={{ width: "20%" }}>
              {" "}
              {t("survey-answer.document.originalname")}
            </th>
            <th style={{ width: "20%" }}>
              {" "}
              {t("survey-answer.document.size")}
            </th>
            <th style={{ width: "15%" }} />
          </tr>
        </thead>
        <tbody>
          {surveyAnswerDocuments?.map((el: DetailSurveyAnswerDocument) => (
            <tr>
              <td>{el.title}</td>
              <td>{el.notes}</td>
              <td>{el.file?.originalname}</td>
              <td>{el.file?.size}</td>
              <td>
                <ButtonGroup>
                  {el.file && (
                    <DownloadButton
                      inTable={true}
                      url={el.file.url}
                      filename={el.file.originalname}
                    />
                  )}
                  <MobileButton
                    inTable={true}
                    icon={faPencil}
                    className="d-block"
                    style={{ width: "100%" }}
                    color={templateTableEditClass}
                    size="sm"
                    id={"edit" + el.id}
                    onClick={() => editSuccessAnswerDocument(el.id)}
                  />
                  <MobileButton
                    inTable={true}
                    icon={faTrash}
                    className="d-block"
                    style={{ width: "100%" }}
                    color={"danger"}
                    size="sm"
                    id={"delete" + el.id}
                    onClick={() => onSurveyAnswerDocumentDelete(el.id)}
                  />
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal isOpen={showProg.display}>
        <ModalHeader>Stiamo caricando i documenti</ModalHeader>
        <ModalBody>
          <>
            Sarai reindirizzato alla pagina principale non appena l'operazione sarà conclusa.
            <Progress animated color="success" min={0} max={100} value={showProg.body.progress * 100} />
          </>
        </ModalBody>
        <ModalFooter>
          <svg viewBox="25 25 50 50" className="svgcircle d-none d-md-inline mt-2">
            <circle r="20" cy="50" cx="50"></circle>
          </svg>
        </ModalFooter>
      </Modal>
    </>
  );
}
