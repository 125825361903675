import { DetailAccomodation } from '../model/accomodation.model';
import { defaultHeaders } from './common';

export const getAccomodationTypesConf = (
) => {
  return {
    method: 'get',
    url:
      '/api/accomodation-type',
    headers: defaultHeaders,
  };
};
export const getAccomodationListConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: 'get',
    url:
      '/api/accomodation?page=' +
      page +
      '&size=' +
      size +
      query +
      '&sort=id,desc',
    headers: defaultHeaders,
  };
};
export const getDetailAccomodationConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/accomodation/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailAccomodationConf = (
  accomodation: DetailAccomodation
) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: `/api/accomodation/${accomodation.id}`,
    headers: defaultHeaders,
    data: JSON.stringify(accomodation),
  };
};

export const createDetailAccomodationConf = (
  accomodation: DetailAccomodation
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: `/api/accomodation`,
    headers: defaultHeaders,
    data: JSON.stringify(accomodation),
  };
};
