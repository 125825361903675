import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import {
  createDetailBadgeConf,
  getDetailBadgeConf,
  updateDetailBadgeConf,
} from '../../api/badgeAPI';
import { getUserListConf } from '../../api/userAPI';
import useRequest from '../../hook/use-request.hook';
import { DetailUser } from '../../model/user.model';
import {
  NotificationTypes,
  useAppUtilContext,
} from '../../util/app-util.context';
import { formattaDateTimeToSave, registerRs } from '../../util/form.util';

export default function BadgeCreatePage() {
  let { id } = useParams();
  const { t } = useTranslation();
  const { showModal } = useAppUtilContext();
  const { showNotification } = useAppUtilContext();
  const [entity, setEntity] = useState<any>({});
  const [user, setUser] = useState<DetailUser[]>([]);

  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();

  useEffect(() => {
    if (id) {
      fetchData(
        getDetailBadgeConf(id),
        (data: any, header: any) => {
          setEntity(data);
        },
        onErrors
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchData(
      getUserListConf(1, 1000, ''),
      (data: any, header: any) => {
        // setProfessionisti(data);
        setUser(data);
      },
      onErrors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    navigate('/badge');
  };

  const onErrors = (errors: any) => {
    // console.log('errors', errors);
    showNotification(errors[0].message, NotificationTypes.ERROR); // Shows first error message in errors array (missing passwords, etc.)
  };

  const onSubmit = (data: any) => {
    const { userId, type, date } = data;
    let d = formattaDateTimeToSave(date);

    if (id) {
      fetchData(updateDetailBadgeConf({ ...data, ...{ date: d , userId: parseInt(data.userId)} }), onSuccess, onErrors);
    } else {
      fetchData(
        createDetailBadgeConf({ userId: parseInt(data.userId), type,  date: d }),
        onSuccess,
        onErrors
      );
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: {}, values: { ...entity } });

  return (
    <div className="container mt-2">
      <Card>
        <CardBody>
          {id ? (
            <CardTitle tag="h5">{t('badge.edit.title')}</CardTitle>
          ) : (
            <CardTitle tag="h5">{t('badge.create.title')}</CardTitle>
          )}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="form-field">
              <Label className="form-label select" for="professionistaId">
                {t('badge.user')}
              </Label>
              <Input
                id="userId"
                name="userId"
                type="select"
                {...registerRs(register, 'userId', {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.userId && errors.userId != null}
              >
                {user.map((it) => {
                  return (
                    <option key={it.id} value={it.id}>
                      {it.firstName} {it.lastName}
                    </option>
                  );
                })}
              </Input>
              <FormFeedback>
                {'' + errors?.professionistaId?.message}
              </FormFeedback>
            </FormGroup>

            <FormGroup className="form-field">
              <Label className="form-label">{t('badge.date')}</Label>
              <Input
                id="date"
                name="date"
                type="datetime-local"
                placeholder={t('user.register.last_name_ph')}
                {...registerRs(register, 'date', {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.date && errors.date != null}
              />{' '}
              <FormFeedback>{'' + errors?.date?.message}</FormFeedback>
            </FormGroup>

            <FormGroup className="form-field">
              <Label className="form-label select" for="type">
                {t('badge.type')}
              </Label>
              <Input
                id="type"
                name="type"
                type="select"
                {...registerRs(register, 'type', {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.type && errors.type != null}
              >
                {['IN', 'OUT'].map((it) => {
                  return (
                    <option key={it} value={it}>
                      {it}
                    </option>
                  );
                })}
              </Input>

              <FormFeedback>{'' + errors?.type?.message}</FormFeedback>
            </FormGroup>

            <Button type="submit" color="primary" disabled={loading}>
              {!loading ? (
                t('general.actions.send')
              ) : (
                <>
                  {/* <Spinner size="sm">Loading...</Spinner>
                  <span> {t('general.actions.loading')}</span> */}
                    <svg viewBox="25 25 50 50" className='svgcircle d-none d-md-inline mt-2'>
                <circle r="20" cy="50" cx="50"></circle>
              </svg>
                </>
              )}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
