import { useEffect, useRef, useState } from "react";
import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import {
  FormWithBootstrap,
  templates,
} from "../../util/form-util";
import { useParams } from "react-router-dom";
import { useAppUtilContext } from "../../util/app-util.context";

import { Button, ButtonGroup, Table } from "reactstrap";
import MobileButton from "../../components/mobile-button";
import { faPencil, faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  templateTableEditClass,
} from "../../util/form.util";
import useRequest from "../../hook/use-request.hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { ImageWidget } from "../../components/TemplateFieldShifter";
import { createDetailAppointmentDocumentConf, deleteAppointmentDocumentConf, getAppointmentDocumentListConf, updateDetailAppointmentDocumentConf } from "../../api/apointmentDocumentAPI";

const widgets = {
  "data-url": ImageWidget,
};

export default function AddApointmentDocument() {
  let { id } = useParams();
  const formRef = useRef<any>(null);
  const [images, setImages] = useState<any>();
  const { loading, fetchData } = useRequest<any>();
  const [entity, setEntity] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      fetchData(
        getAppointmentDocumentListConf("?appointmentId=" + id),
        onSuccessDetail,
        onErrorsModal
      );
    }
  }, [id]);

  const { onSuccessModal, onErrorsModal, showModal, closeModal } = useAppUtilContext();

  const onSuccessDetail = (dto: any) => {
    setImages(dto);
    closeModal()
  };

  const editImage = (editId: any) => {
    const data = editId ? images.find((el: any) => el.id === editId) : {};
    // console.log("editImage data", data)
    setEntity(data);
  };
  const createImage = () => {
    setEntity({ appointmentId: parseInt(id ? id : "0") });
  };

  const onChange = (props: any) => {
    const { formData } = props;
    setEntity(formData);
  };

  const onSubmit = ({ formData }: any) => {
    closeModal();
    showModal("Stiamo caricando i documenti", "sarai indirizzato alla pagina non appena l'operazione sarà conclusa", <></>, <svg viewBox="25 25 50 50" className='svgcircle d-none d-md-inline mt-2'>
      <circle r="20" cy="50" cx="50"></circle>
    </svg>);
    if (formData.id) onImageEdit({ ...formData });
    else onImageCreate({ ...formData });
  };

  const onSuccess = (props: any) => {
    fetchData(
      getAppointmentDocumentListConf("?appointmentId=" + id),
      onSuccessDetail,
      onErrorsModal
    );
  };

  useEffect(() => {
    if (entity) {
      const schema: RJSFSchema = {
        title: "",
        type: "object",
        properties: {
          id: {
            type: "number",
            title: t('general.id'),
            readOnly: true,
          },
          appointmentId: {
            type: "number",
            title:  t('appointment.document.appointmentId'),
            readOnly: true,
          },
          title: {
            type: "string",
            title:  t('appointment.document.title'),
          },
          notes: {
            type: "string",
            title:  t('appointment.document.notes'),
          },
          documentData: {
            type: "string",
            title:  t('appointment.document.upload'),
            format: "data-url",
          },
        },
        required: ["documentData", "title"],
      };
      const uiSchema = {
        id: { "ui:widget": "hidden" },
        appointmentId: { "ui:widget": "hidden" },
      };

      showModal(
       entity.id ? t('appointment.document.edit') : t('appointment.document.add'),
        <>
          <FormWithBootstrap
            formData={entity}
            ref={formRef}
            schema={schema}
            uiSchema={uiSchema}
            onChange={onChange}
            onSubmit={onSubmit}
            templates={templates}
            widgets={widgets}
            validator={validator}
            children={true}
          />{" "}
        </>,
        null,
        <MobileButton
          inTable={true}
          icon={faSave}
          className="d-block"
          style={{ width: "100%" }}
          color={'primary'}
          text={t("general.buttons.send")}
          onClick={() => {
            const err = formRef?.current.validateForm();

            if (err) formRef?.current.submit();
          }}
        />
      );
    }
  }, [entity]);

  const onImageCreate = (formData: any) => {
    fetchData(
      createDetailAppointmentDocumentConf(formData),
      onSuccess,
      onErrorsModal
    );
  };
  const onImageEdit = (formData: any) => {
    fetchData(
      updateDetailAppointmentDocumentConf(formData),
      onSuccess,
      onErrorsModal
    );
  };
  
  const onImageDelete = (editId: any) => {
    fetchData(
      deleteAppointmentDocumentConf(editId),
      onSuccess,
      onErrorsModal
    );
    //setEntity(null);
  };

  return (
    <>
      <div className="header-container">
        <h2 className="page-title">  {t('appointment.document.doctitle')}</h2>
        <Button
          onClick={createImage}
          color={templateTableEditClass}
          style={{ float: "right", marginTop: "5px" }}
        >
          <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faPlus} />{" "}
          {t('appointment.document.add')}
        </Button>
      </div>

      <Table striped responsive>
            <thead>
              <tr style={{ whiteSpace: 'break-spaces' }}>
                <th style={{ width: '20%' }}> {t('appointment.document.title')}</th>
                <th style={{ width: '20%' }}> {t('appointment.document.notes')}</th>
                <th  style={{ width: '15%' }} />
              </tr>
            </thead>
            <tbody>
      {images?.map((el: any) => (
<tr>
  <td>{el.title}</td>
  <td>{el.notes}</td><td>
     <ButtonGroup>   <MobileButton
          inTable={true}
          icon={faPencil}
          className="d-block"
          style={{ width: "100%" }}
          color={templateTableEditClass}
          size="sm"
          id={"view" + el.id}
          onClick={() => editImage(el.id)}
        />
        <MobileButton
          inTable={true}
          icon={faTrash}
          className="d-block"
          style={{ width: "100%" }}
          color={templateTableEditClass}
          size="sm"
          id={"view" + el.id}
          onClick={() => onImageDelete(el.id)}
        />
                      </ButtonGroup>
        
        </td></tr>
      ))}
      </tbody>
</Table>

    </>
  );
}
