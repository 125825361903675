import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorsView } from '../../components/errors.view';
import useRequest from '../../hook/use-request.hook';
import { ApiError } from '../../model/errors.model';
import { useAppUtilContext } from '../../util/app-util.context';
import {
  getPrintSurveyAnswerConf,
} from '../../api/surveyAnswerAPI';
import BreadcrumbsUtility from '../../components/breadcrumb';
import { DetailSurveyAnswer } from '../../model/survey-answer.model';
import { currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import SurveyTemplateStatic from '../survey-template/survey-template-static';
import { getDetailArchivedSurveyAnswerConf } from '../../api/archivedSurveyAnswerAPI';

export const bonificaAnswer = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === 'string' && obj[key] === '') {
      obj[key] = undefined; // Imposta la stringa vuota a null
    } else if (obj[key] !== null && typeof obj[key] === 'object') {
      bonificaAnswer(obj[key]); // Ricorsione per oggetti annidati
    }
  }
  return obj;
};

export default function ArchivedSurveyAnswerDetailPage(props: any) {
  const { edit } = props;
  let {  archivedId } = useParams();
  const navigate = useNavigate();
  const { showModal } = useAppUtilContext();
  const { fetchData: fetchDataDetail } = useRequest<any>();
   const [answer, setAnswer] = useState<DetailSurveyAnswer>();
  const [entity, setEntity] = useState<any>();
  const [jsonSchema, setJsonSchema] = useState<any[]>();
  const [uiSchema, setUiSchema] = useState<any>();
  const [tradSchema, setTradSchema] = useState<any[]>();
  const [datas, setDatas] = useState<any>([]);

  useEffect(() => {
    if (archivedId) {
      fetchDataDetail(
        getDetailArchivedSurveyAnswerConf(archivedId),
        onSuccessAnswer,
        onErrorsDetail
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archivedId]);

  const printAnswer = () => {
    if (archivedId) {
      fetchDataDetail(
        getPrintSurveyAnswerConf(archivedId),
        (data: any, header: any) => {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          });
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = 'stampa.docx';
          a.click();
        },
        onErrorsDetail
      );
    }
  };

  useEffect(() => {
    if (entity) {
      let schemaObj = entity.templateJsonSchema;
      let uiObj = entity.templateUiSchema;
      let tradObj = entity.templateTradSchema;
      setJsonSchema(schemaObj);
      setUiSchema(uiObj);
      setTradSchema(tradObj);
    }
  }, [entity]);

  const onSuccessAnswer = (ans: any) => {
    let jsonDatasClean = bonificaAnswer(ans.surveyAnswerDatas	);

    setAnswer({ ...ans, ...{ jsonDatas: jsonDatasClean}});
    setDatas(jsonDatasClean);
    setEntity(ans	);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };
  const maxstep = Array.isArray(jsonSchema) ? jsonSchema.length : 1;
  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isOperatore = currentUser?.authorityChecks.isOperatore;

  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          { text: t('survey-answer.list.title'), link: '/survey-answer' },
          { text: t('survey-answer.detail.title') },
        ]}
      />
      <div className="header-container">
        <h2 className="page-title">{t('survey-answer.detail.title')}</h2>
  
        {(isSportellista || isAdministrator || isOperatore) && (
          <Button
            text="print"
            onClick={printAnswer}
            color={'dark'}
            style={{ marginBottom: '19px', marginTop: '19px' }}
          >
            <FontAwesomeIcon
              style={{ marginRight: '1rem' }}
              icon={faListCheck}
            />{' '}
            {t('general.buttons.print')}
          </Button>
        )}
      </div>
      {maxstep > 0 && (
        <div>
          <SurveyTemplateStatic
            onSubmit={(newdata:any) => {
              // if(edit){
              //   fetchDataDetail(
              //     updateDetailSurveyAnswerConf({ jsonDatas: newdata, id: answer?.id as number, surveyTemplateId: answer?.surveyTemplate.id }),
              //     onSuccessEdit,
              //     onErrorsDetail
              //   );
              // }
            }}     
            jsonSchema={jsonSchema}
            uiSchema={uiSchema}
            tradSchema={tradSchema}
            formDatas={datas}
            isTradEnabled={false}
            isReadMode={edit ? false : true}
          />
        </div>
      )}
    </div>
  );
}
