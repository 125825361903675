import {
  faCheckDouble,
  faEye,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, ButtonGroup, Table } from 'reactstrap';
import { getSurveyAnswerListConf } from '../../api/surveyAnswerAPI';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import PaginationUtility from '../../components/pagination';
import useRequest from '../../hook/use-request.hook';
import { LookupSurveyAnswer } from '../../model/survey-answer.model';
import { LookupSurveyTemplate } from '../../model/survey-template.model';
import {
  currentUserSelector,
  userLngSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import { useAppUtilContext } from '../../util/app-util.context';
import { isStringified } from '../survey-template/survey-template-edit.page';

export default function SurveyAnswerListPage(props: any) {
  const { user } = props;
  const { onErrorsModal } = useAppUtilContext();
  const currentUser = useAppSelector(currentUserSelector);
  const [templateList, setTemplateList] = useState<LookupSurveyTemplate[]>([]);
  const navigate = useNavigate();
  const { fetchData } = useRequest();
  const [list, setList] = useState<LookupSurveyAnswer[]>([]);
  const lang = useAppSelector(userLngSelector);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  useEffect(() => {
    setList(
      list.map((el: any) => {
        let title = isStringified(el.stTitle);
        return { ...el, ...{ title: title[lang], titleFull: title } };
      })
    );
    setTemplateList(
      templateList.map((el: any) => {
        return { ...el, ...{ title: el.titleFull[lang] } };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const refreshFirstPage = () => {
    setPagination({
      page: 1,
      pageSize: 21,
      totalElements: null,
    });

    refresh(pagination);
  };
  useEffect(() => {
    refreshFirstPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = (pag: any) => {
    let query = '';
    query = query + '&userId=' + user;

    fetchData(
      getSurveyAnswerListConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(
          data.map((el: any) => {
            let title = isStringified(el.stTitle);
            return { ...el, ...{ title: title[lang], titleFull: title } };
          })
        );
        setPagination({
          ...pag,
          ...{ totalElements: header['x-total-count'] },
        });
      },
      onErrorsModal
    );
  };

  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;

  return (
    <>
      <div className="header-container">
        <h2 className="page-title">{t('home.survey-answer')}</h2>
      </div>
      {pagination.totalElements === 0 && (
        <div className="pb-3 pt-3 fs-5">
          {t('general.errors.no_items_found')}
        </div>
      )}
      {pagination.totalElements > 0 && (
        <div className="mt-2 row">
          <Table striped responsive>
            <thead>
              <tr style={{ whiteSpace: 'break-spaces' }}>
                <th style={{ width: '10%' }}>{t('general.id')}</th>{' '}
                <th style={{ width: '10%' }}>
                  {t('survey-answer.surveyTemplate')}
                </th>
                <th style={{ width: '10%' }}>{t('survey-answer.status')}</th>
                <th rowSpan={2} style={{ width: '13%' }}></th>
              </tr>
            </thead>
            <tbody>
              {list.map((it, idx) => {
                return (
                  <tr key={it.saId}>
                    <td style={{ width: 'auto' }}>{it.saId}</td>
                    <td style={{ width: 'auto' }}>{it.title}</td>
                    <td>
                    {it.saVerificationStatus == null ||
                      it.saVerificationStatus === 'NOT_VERIFIED' ? (
                        <Badge color="secondary">{t('survey-answer.status-NOT_VERIFIED')}</Badge>
                      ) : it.saVerificationStatus === 'SENT' ? (
                        <Badge color="warning">{t('survey-answer.status-SENT')}</Badge>
                      ) : (
                        <Badge color="success">{t('survey-answer.status-VERIFIED')}</Badge>
                      )}
                    </td>
                    <td style={{ whiteSpace: 'break-spaces' }}>
                      <ButtonGroup>
                        <MobileButton
                          inTable={true}
                          icon={faEye}
                          className="d-block"
                          outline
                          color={'primary'}
                          size="sm"
                          id={'view' + it.saId}
                          onClick={() => navigate(`/survey-answer/${it.saId}`)}
                        />
                        {(isAdministrator || isSportellista) && (
                          <MobileButton
                            inTable={true}
                            icon={faPencil}
                            className="d-block"
                            color={'primary'}
                            size="sm"
                            id={'view' + it.saId}
                            onClick={() =>
                              navigate(`/survey-answer/${it.saId}/edit`)
                            }
                          />
                        )}
                        {(isAdministrator || isSportellista) && (
                          <MobileButton
                            inTable={true}
                            icon={faCheckDouble}
                            className="d-block"
                            color={'success'}
                            size="sm"
                            text={t('general.buttons.verify')}
                            id={'view' + it.saId}
                            onClick={() =>
                              navigate(`/survey-answer/${it.saId}/SMSconfirm`)
                            }
                            disabled={it.saVerificationStatus === 'VERIFIED'}
                            outline={it.saVerificationStatus === 'VERIFIED'}
                          />
                        )}
                      </ButtonGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      <div className="pb-5">
        <PaginationUtility
          {...pagination}
          onSizeChange={(n: any) => {
            if (pagination.pageSize !== n)
              refresh({ ...pagination, ...{ page: 1, pageSize: n } });
          }}
          onChange={(n: any) => {
            if (pagination.page !== n)
              refresh({ ...pagination, ...{ page: n } });
          }}
        />
      </div>
    </>
  );
}
