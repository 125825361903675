import { Button } from 'reactstrap';
import {
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import BadgePersonal from '../badge/badge-personal';
import HomeSurveyAnswerListPage from './answer-list.page';
import HomeAppointmentListPage from './appointment-list.page';
import HomeUserListPage from './user-list.page';

export default function HomePage() {
  const currentUser = useAppSelector(currentUserSelector);

  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isProfessionista = currentUser?.authorityChecks.isProfessionista;
  const isOperatore = currentUser?.authorityChecks.isOperatore;
  const isUser = currentUser?.authorityChecks.isUser;

  return (
    <div className="container-fluid mt-2">
      {!currentUser && (
        <div style={{ textAlign: 'center' }}>
          <Button
            style={{
              display: 'inline',
              fontSize: '2rem',
              width: '90%',
              margin: '0 1rem',
            }}
            sie="lg"
            color="primary"
          >
            Agenzia sociale casa
          </Button>
        </div>
      )}

      <div className="row">
        {isProfessionista || isSportellista ? <BadgePersonal home /> : ''}
        {currentUser ? (
          isProfessionista ? (
            <div
              className="col-lg-12 col-md-12 col-sm-12"
              style={{ marginBottom: '2rem' }}
            >
              <div className="card">
                <div className="container-fluid">
                  <HomeAppointmentListPage fixedUser={currentUser.id} />
                </div>
              </div>
            </div>
          ) : (
            <div
              className="col-lg-12 col-md-12 col-sm-12"
              style={{ marginBottom: '2rem' }}
            >
              <div className="card">
                <div className="container-fluid">
                  <HomeAppointmentListPage />
                </div>
              </div>
            </div>
          )
        ) : (
          ''
        )}
        {isAdministrator || isSportellista || isOperatore ? (
          <HomeUserListPage withLink />
        ) : (
          ''
        )}
        {isAdministrator || isSportellista || isOperatore || isUser ? (
          <HomeSurveyAnswerListPage withLink />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
