import { Route } from 'react-router-dom';
import {
  currentUserSelector,
  userAccessTokenSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import ActivatePage from './activate.page';
import BadgeInOut from './badge-in-out';
import ForgotPasswordPage from './forgot-password.page';
import LoginPage from './login.page';
import RegisterPage from './register.page';
import ResetPasswordPage from './reset-password.page';
import UserListPage from './user-list.page';

const UserRoutes = () => {
  const accessToken = useAppSelector(userAccessTokenSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isProfessionista = currentUser?.authorityChecks.isProfessionista;
  const isOperatore = currentUser?.authorityChecks.isOperatore;

  return (
    <ErrorBoundaryRoutes>
      {!accessToken && (
        <>
          <Route path="login" element={<LoginPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
        </>
      )}
      {/* {accessToken && <Route path="detail" element={<RegisterPage blocked />} />} */}
      {accessToken && (isAdministrator || isSportellista) && (
        <Route path="register" element={<RegisterPage />} />
      )}
      {accessToken && (isAdministrator || isSportellista) && (
        <Route path=":id/edit" element={<RegisterPage />} />
      )}
      {accessToken && (isAdministrator || isSportellista || isOperatore) && (
        <Route path=":id" element={<RegisterPage blocked />} />
      )}
      {accessToken && (isSportellista || isProfessionista) && (
        <Route path="badge-in-out" element={<BadgeInOut />} />
      )}
      {accessToken && (isAdministrator || isSportellista || isOperatore) && (
        <Route path="*" element={<UserListPage />} />
      )}

      <Route path="reset-password/:code" element={<ResetPasswordPage />} />
      <Route path="activate/:code" element={<ActivatePage />} />
    </ErrorBoundaryRoutes>
  );
};

export default UserRoutes;
