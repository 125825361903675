import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbsUtility from '../../components/breadcrumb';
import { ErrorsView } from '../../components/errors.view';
import useRequest from '../../hook/use-request.hook';
import { ApiError } from '../../model/errors.model';
import { currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import {
  NotificationTypes,
  useAppUtilContext,
} from '../../util/app-util.context';
import {
  FormWithBootstrap,
  templates,
  transformErrors,
} from '../../util/form-util';
import { DetailAccomodationOwner } from '../../model/accomodationOwner.model';
import { createDetailAccomodationOwnerConf, getDetailAccomodationOwnerConf, updateDetailAccomodationOwnerConf } from '../../api/accomodationOwnerAPI';
import { Button } from 'reactstrap';
import { templateTableEditClass } from '../../util/form.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

export default function RealEstateDetailPage(props: any) {
  const { showNotification } = useAppUtilContext();
  const { edit } = props;
  
  let { id } = useParams();
  const navigate = useNavigate();
  const { showModal, onErrorsModal } = useAppUtilContext();
  const { fetchData: fetchDataDetail } = useRequest<any>();
  const [entity, setEntity] = useState<DetailAccomodationOwner>();
  const { loading, fetchData } = useRequest();

  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailAccomodationOwnerConf(id),
        onSuccessDetail,
        onErrorsModal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccessDetail = (dto: DetailAccomodationOwner) => {
    setEntity(dto);
  };

  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;
  const isOperatore = currentUser?.authorityChecks.isOperatore;

  let datas = { ...entity };

  const schema: RJSFSchema = {
    type: 'object',
    required: ['name','surname','address','fiscalCode','city','zip'],
    properties: {
      id: {
        title: t('general.id'), 
        type: 'number',
      },
      
      name: {
        type: 'string',
        title: t('accomodationOwner.name'), 
      },
      surname: {
        type: 'string',
        title: t('accomodationOwner.surname'), 
      },
      address: {
        type: 'string',
        title: t('accomodationOwner.address'), 
      },
      fiscalCode: {
        type: 'string',
        title: t('accomodationOwner.fiscalCode'), 
        pattern: "^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$",
      },
      city: {
        type: 'string',
        title: t('accomodationOwner.city'), 
      },
      zip: {
        type: 'string',
        title: t('accomodationOwner.zip'), 
        pattern: "^[0-9]{6}$"
      },
      emails: {
        type: 'array',
        title: t('accomodationOwner.emails'), 
        items: {
          type: 'string',
          format: "email",
        },
      },
      phoneNumbers: {
        type: 'array',
        title: t('accomodationOwner.phoneNumbers'), 
        items: {
          type: 'string',
        },
      }
      
    },
    additionalProperties: false,
  };
  
  const uiSchema = {
    id: { 'ui:widget': 'hidden' },
    "ui:options":{
      "customTemplate":"MultiColumns",
      "cols":{
        "city":6,"zip":6, 
        name:6,
        surname:6
    }}
  };

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    if (isAdministrator || isSportellista) navigate('/accomodation-owner');
  };

  const onFormSubmit = ({ formData }: any) => {
   // console.log('onFormSubmit', formData);

    if (id) {
      fetchData(updateDetailAccomodationOwnerConf(formData), onSuccess, onErrorsModal);
    } else {
      fetchData(createDetailAccomodationOwnerConf(formData), onSuccess, onErrorsModal);
    }
  };

  const transformErrorsCustom = (errors: any) => {
    let e = transformErrors(errors)

    if((datas?.emails? datas?.emails.length : 0) + (datas?.phoneNumbers? datas?.phoneNumbers.length : 0)  == 0 ){
      e.push({
        stack: '.emails: Hai bisogno di inserire almeno un recapito',
        message: t('accomodationOwner.no_recapito'),
        name: 'match',
        property: '.emails',
      });
      e.push({
        stack: '.phoneNumbers: Hai bisogno di inserire almeno un recapito',
        message: t('accomodationOwner.no_recapito'),
        name: 'match',
        property: '.phoneNumbers',
      });
    }
    
    return e
  }

  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          {
            text: t('accomodationOwner.list.title'),
            link: '/accomodation-owner',
          },
          { text: t('accomodationOwner.detail.title') },
        ]}
      />
      <div className="header-container">
        <h2 className="page-title">{t('accomodationOwner.detail.title')}</h2>

        {(isAdministrator || isSportellista) && id && !edit && (
              <Button
                onClick={() => navigate(`/accomodation-owner/${id}/edit`)}
                color={templateTableEditClass}
                style={{ float: 'right', marginTop: '5px' }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: '1rem' }}
                  icon={faPencil}
                />{' '}
                {t('general.buttons.edit')}
              </Button>
            )}
      </div>
      <FormWithBootstrap
        templates={templates}
        onSubmit={onFormSubmit}
        formData={datas}
        onChange={(data : any) => {
          datas = { ...data.formData };
        }}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        transformErrors={transformErrorsCustom}
        showErrorList={false}
        readonly={!edit}
        children={!edit}
      ></FormWithBootstrap>
    </div>
  );
}
