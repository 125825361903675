import {
  faCheck,
  faClipboardCheck,
  faClipboardQuestion,
  faEye,
  faPencil,
  faPlus,
  faRefresh,
  faSearch,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Spinner,
  Table,
} from 'reactstrap';
import { getSurveyTemplateListConf } from '../../api/surveyTemplateAPI';
import BreadcrumbsUtility from '../../components/breadcrumb';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import PaginationUtility from '../../components/pagination';
import useRequest from '../../hook/use-request.hook';
import { LookupSurveyTemplate } from '../../model/survey-template.model';
import { useAppUtilContext } from '../../util/app-util.context';
import { isStringified } from './survey-template-edit.page';
import { useAppSelector } from '../../redux/store';
import { userLngSelector } from '../../redux/reducers/userSlice';
import { templateTableEditClass, templateTableShowClass } from '../../util/form.util';

export default function SurveyTemplateListPage() {
  const { onErrorsModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<LookupSurveyTemplate[]>([]);
  // const [status, setStatus] = useState<any>(null);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [text, setText] = useState<any>(null);
  const lang = useAppSelector(userLngSelector);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  const handleChange = (e: any) => {
    if (e.target.id === 'fromDate') setFromDate(e.target.value);
    else if (e.target.id === 'toDate') setToDate(e.target.value);
  };

  const handleTextChange = (e: any) => {
    setText(e.target.value);
  };

  const refreshFirstPage = () => {
    setPagination({
      page: 1,
      pageSize: 21,
      totalElements: null,
    });

    refresh(pagination);
  };

  useEffect(() => {
    refreshFirstPage();
  }, []);

  useEffect(() => {
    setList(
      list.map((el: any) => {
        return { ...el, ...{ title: el.titleFull[lang] } };
      })
    );
  }, [lang]);

  const refresh = (pag: any) => {
    let query = '';
    // if (status) query = '&status=' + status;
    if (fromDate) query = query + '&fromDate=' + fromDate;
    if (toDate) query = query + '&toDate=' + toDate;
    if (text) query = query + '&text=' + text;

    fetchData(
      getSurveyTemplateListConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(
          data.map((el: any) => {
            let title = isStringified(el.title);
            return {
              ...el,
              ...{ title: title ? title[lang] : null, titleFull: title },
            };
          })
        );
        setPagination({
          ...pag,
          ...{ totalElements: header['x-total-count'] },
        });
      },
      onErrorsModal
    );
  };

  return (
    <div className="container mt-2">
      <BreadcrumbsUtility links={[{ text: t('survey-template.list.title') }]} />

      <div className="header-container">
        <h2 className="page-title">{t('survey-template.list.title')}</h2>
      </div>
      <div className="filter-card card mb-2">
        <div className="card-body">
          <h6 className="mt-1 mb-3">{t('general.actions.filter')}</h6>
          <div className="mt-2">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10">
                <FormGroup>
                  <div
                    style={{ marginBottom: '.5rem', display: 'inline-block' }}
                  >
                    {t('general.title.text')}
                  </div>
                  <Input
                    id="title"
                    name="title"
                    type="text"
                    onChange={handleTextChange}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-5">
                <FormGroup>
                  <div
                    style={{ marginBottom: '.5rem', display: 'inline-block' }}
                  >
                    {t('general.date.from')}
                  </div>
                  <Input
                    id="fromDate"
                    name="fromDate"
                    type="date"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-5">
                <FormGroup>
                  <div
                    style={{ marginBottom: '.5rem', display: 'inline-block' }}
                  >
                    {t('general.date.to')}
                  </div>
                  <Input
                    id="toDate"
                    name="toDate"
                    type="date"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2">
                <div style={{ marginBottom: '.5rem' }}>&nbsp;</div>
                <Button color="primary" onClick={refreshFirstPage}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="justify-content-end pt-3"
        style={{ display: 'flex', gap: '10px' }}
      >
        <MobileButton
          color="secondary"
          outline
          onClick={() => navigate(`/survey-template/create`)}
        >
          <FontAwesomeIcon icon={faPlus} />
          <span className="d-none d-md-inline">
            &nbsp; {t('general.buttons.create')}
          </span>
        </MobileButton>
        <MobileButton
          onClick={() => refresh(pagination)}
          disabled={loading}
          color="secondary"
          outline
        >
          {!loading ? (
            <>
              <FontAwesomeIcon icon={faRefresh} />
              <span className="d-none d-md-inline">
                &nbsp; {t('general.buttons.refresh')}
              </span>
            </>
          ) : (
            <>
              {/* <Spinner size="sm">Loading...</Spinner>
              <span className="d-none d-md-inline">
                {' '}
                {t('general.actions.loading')}{' '}
              </span> */}
              <svg viewBox="25 25 50 50" className='svgcircle d-none d-md-inline mt-2'>
                <circle r="20" cy="50" cx="50"></circle>
              </svg>
            </>
          )}
        </MobileButton>
      </div>
      {pagination.totalElements == 0 && (
        <div className="pb-3 pt-3 fs-5">
          {t('general.errors.no_items_found')}
        </div>
      )}
      {pagination.totalElements > 0 && !loading && (
        <div className="mt-2 row">
          <Table striped responsive>
            <thead>
              <tr style={{ whiteSpace: 'break-spaces' }}>
                <th style={{ width: '10%' }}>{t('general.id')}</th>
                <th style={{ width: '10%' }}>{t('survey-template.title')}</th>
                <th style={{ width: '10%' }}>
                  {t('survey-template.updatedAt')}
                </th>
                <th style={{ width: '10%' }}>
                  {t('survey-template.translated')}
                </th>
                <th style={{ width: '10%' }}>{t('survey-template.enabled')}</th>
                <th rowSpan={2} style={{ width: '13%' }}></th>
              </tr>
              <tr style={{ whiteSpace: 'break-spaces' }}></tr>
            </thead>
            <tbody>
              {list.map((it, idx) => {
                return (
                  <tr key={it.id}>
                    <td style={{ width: 'auto' }}>{it.id}</td>
                    <td
                      data-label={t('survey-template.title')}
                      style={{ width: 'auto' }}
                    >
                      {it.title}
                    </td>
                    <td
                      data-label={
                        t('general.date.from') + ' ' + t('general.date.to')
                      }
                      style={{ width: 'auto' }}
                    >
                      {it.updatedAt}
                    </td>
                    <td style={{ width: 'auto' }}>
                      {it.translated ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        <FontAwesomeIcon icon={faXmark} />
                      )}
                    </td>
                    <td style={{ width: 'auto' }}>
                      {it.enabled ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        <FontAwesomeIcon icon={faXmark} />
                      )}
                    </td>
                    <td>
                      <ButtonGroup style={{ width: '100%' }}>
                        <MobileButton
                          inTable={true}
                          icon={faEye}
                          className="d-block"
                          style={{ width: '100%' }}
                          color={templateTableShowClass}
                          size="sm"
                          text={t('general.buttons.detail')}
                          id={'view' + it.id}
                          onClick={() => navigate(`/survey-template/${it.id}`)}
                        />
                        <MobileButton
                          inTable={true}
                          icon={faPencil}
                          className="d-block"
                          style={{ width: '100%' }}
                          color={templateTableEditClass}
                          size="sm"
                          text={t('general.buttons.edit')}
                          id={'view' + it.id}
                          onClick={() =>
                            navigate(`/survey-template/${it.id}/edit`)
                          }
                        />
                        <MobileButton
                          inTable={true}
                          icon={faClipboardQuestion}
                          className="d-block"
                          style={{ width: '100%' }}
                          color={'success'}
                          disabled={!it.enabled}
                          outline={!it.enabled}
                          size="sm"
                          text={t('general.buttons.answer')}
                          id={'view' + it.id}
                          onClick={() =>
                            navigate(`/survey-template/${it.id}/answer`)
                          }
                        />
                      </ButtonGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      <div className="pb-5">
        <PaginationUtility
          {...pagination}
          onSizeChange={(n: any) => {
            if (pagination.pageSize !== n)
              refresh({ ...pagination, ...{ page: 1, pageSize: n } });
          }}
          onChange={(n: any) => {
            if (pagination.page !== n)
              refresh({ ...pagination, ...{ page: n } });
          }}
        />
      </div>
    </div>
  );
}
