import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  Spinner,
} from "reactstrap";
import {
  geCurrentUserConf,
  getBadgeInOutConf,
} from "../../api/userAPI";
import useRequest from "../../hook/use-request.hook";
import { currentUserSuccess } from "../../redux/reducers/userSlice";
import { AppThunkDispatch, useAppSelector } from "../../redux/store";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppUtilContext } from "../../util/app-util.context";
import { useMemo, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListCheck, faRightFromBracket, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { formattaFullData, formattaReadableData, templateGoToActionClass } from "../../util/form.util";

export default function BadgePersonal(props: any) {
  const { home, onCreate }= props
  const dispatch = useDispatch() as AppThunkDispatch;
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const badgeListRef = useRef<any>(null);
  const { loading, fetchData } = useRequest();
  const currentUser = useAppSelector((state) => state.user.currentUser);

  const isIn = useMemo(() => {
    return currentUser && currentUser.badge && currentUser.badge.type == 'IN';
  }, [currentUser]);

  const onSuccessRefresh = (data: any) => {
    if(onCreate)
      onCreate()
    dispatch(currentUserSuccess(data));
  };
  
  const onSuccess = (data: any) => {
    let text = data.message;
    
    badgeListRef.current?.refresh()
    fetchData(geCurrentUserConf(), onSuccessRefresh, onErrors);

    showModal(t("modal.request_success"), text, null);
  };

  const onErrors = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t("modal.request_failed"), errorsView, null);
  };

  const onSubmit = (data: any) => {
    fetchData(getBadgeInOutConf(!isIn), onSuccess, onErrors);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const lastBadge = currentUser && currentUser?.badge ? formattaFullData(currentUser.badge.date, t) : null;

  return (
    <div className="container mt-4 mb-4 ">
      <Card>
        <CardBody>
          <CardTitle tag="h5">{t("user.badge.title")}{home? 
         <Button onClick={() => navigate(`/user/badge-in-out`)} color={templateGoToActionClass} style={{float: 'right', marginTop: '5px'}} >
       <FontAwesomeIcon  style={{ marginRight: '1rem' }} icon={faListCheck} />{" "}{t("user.badge.list")}
       </Button>   : '' }</CardTitle> 
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Alert color="info" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1.25rem' }}>
              <span>{isIn ? t("user.badge.badgein") : t("user.badge.badgeout")}
             {currentUser && currentUser?.badge && <><br /><small>
              {isIn ? t("user.badge.lastbadgein") : t("user.badge.lastbadgeout")}:{" "}
             {lastBadge}</small></>}
              </span>
              <Button color={isIn ? "warning" : "success"} type="submit" disabled={loading}>
              {!loading ? (
                isIn ? (
                  <>{t("user.badge.actionbadgeout")}<FontAwesomeIcon style={{ marginLeft: '1rem' }} icon={faRightFromBracket} /></>
                ) : (
                  <>{t("user.badge.actionbadgein")}{' '}<FontAwesomeIcon style={{ marginLeft: '1rem' }} icon={faRightToBracket} /></>
                )
              ) : (
                <>
                  {/* <Spinner size="sm">Loading...</Spinner>
                  <span> {t("general.actions.loading")}</span> */}
                    <svg viewBox="25 25 50 50" className='svgcircle d-none d-md-inline mt-2'>
                      <circle r="20" cy="50" cx="50"></circle>
                    </svg>
                </>
              )}
            </Button>
            </Alert>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
