import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Authorities, currentUserSelector } from '../redux/reducers/userSlice';
import { useAppSelector } from '../redux/store';
import { checkRequiredAuthorities } from './authority-boundary-view';

export interface BreadcrumbsItem {
  text: string;
  link?: string;
}
interface BreadcrumbsUtilityProps {
  links: BreadcrumbsItem[];
}
const BreadcrumbsUtility = (props: BreadcrumbsUtilityProps) => {
  const { links } = props;
  const currentUser = useAppSelector(currentUserSelector);
  const isAdministratorOrAssistente = checkRequiredAuthorities(
    [Authorities.ADMIN, Authorities.SPORTELLISTA],
    currentUser
  );

  return currentUser && isAdministratorOrAssistente ? (
    <Breadcrumb>
      <BreadcrumbItem>
        <Link to={'/'}>{t('menu.home')}</Link>
      </BreadcrumbItem>
      {links.map((el, index) => {
        if (index === links.length - 1) {
          return <BreadcrumbItem active={true}>{el.text}</BreadcrumbItem>;
        } else if (el.link) {
          return (
            <BreadcrumbItem>
              <Link to={el.link}> {el.text}</Link>
            </BreadcrumbItem>
          );
        }
        return null;
      })}
    </Breadcrumb>
  ) : (
    <></>
  );
};

export default BreadcrumbsUtility;
