import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BreadcrumbsUtility from '../../components/breadcrumb';
import { ErrorsView } from '../../components/errors.view';
import useRequest from '../../hook/use-request.hook';
import { ApiError } from '../../model/errors.model';
import { Authorities, currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import {
  NotificationTypes,
  useAppUtilContext,
} from '../../util/app-util.context';
import {
  FormWithBootstrap,
  templates,
  transformErrors,
} from '../../util/form-util';
import AddAccomodationInspectionImages from './add-accomodation-inspection-images.view';
import { createDetailAccomodationInspectionConf, getDetailAccomodationInspectionConf, updateDetailAccomodationInspectionConf } from '../../api/accomodationInspectionAPI';
import { DetailAccomodationInspection } from '../../model/accomodation-inspection.model';
import { getAccomodationListConf } from '../../api/accomodationAPI';
import { formattaDateTimeToSave, templateTableEditClass } from '../../util/form.util';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

export default function RealEstateDetailPage(props: any) {
  const { showNotification } = useAppUtilContext();
  const { edit } = props;
  let { id } = useParams();
  const navigate = useNavigate();
  const { showModal, onErrorsModal } = useAppUtilContext();
  const { fetchData: fetchDataDetail } = useRequest<any>();
  const [entity, setEntity] = useState<DetailAccomodationInspection>();
  const { loading, fetchData } = useRequest();
  const [accomodation, setAccomodatio] = useState<any[]>([]);

  useEffect(() => {
    fetchDataDetail(
      getAccomodationListConf(1, 1000, ''),
    (data) => {
      setAccomodatio(data)
    },
      onErrorsModal
    );
  }, []);

  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailAccomodationInspectionConf(id),
        onSuccessDetail,
        onErrorsModal
      );
    }
  }, [id]);


  const onSuccessDetail = (dto: DetailAccomodationInspection) => {
    setEntity(dto);
  };

  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;

  const datas = { ...entity };

  const schema: RJSFSchema = {
    // title: t('accomodationInspection.title'),
    type: 'object',
    required: [],
    properties: {
      id: {
        title: t('general.id'),
        type: 'number',
      },
      date: {
        type: "string",
        title: t('accomodationInspection.date'),
        format: "date-time",
      },
      accomodationId: {
        type: "number",
        title: t('accomodationInspection.accomodationId'),
        oneOf: accomodation.map((el : any) => {
          return { const: el.id, title: el.address+ " "+ el.city }
        })
      },
      notes: {
        type: "string",
        title: t('accomodationInspection.notes'),
      },
    }
  };

  const uiSchema = {
    id: { 'ui:widget': 'hidden' }
  };

  const onErrors = (errors: any) => {
    showNotification(errors[0].message, NotificationTypes.ERROR); // Shows first error message in errors array (missing passwords, etc.)
  };

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    if (isAdministrator || isSportellista) navigate('/accomodation-inspection');
  };

  const onFormSubmit = ({ formData }: any) => {
    let f = { ...formData, ...{ date : formattaDateTimeToSave(formData.date)} }

    if (id) {
      fetchData(updateDetailAccomodationInspectionConf(f), onSuccess, onErrors);
    } else {
      fetchData(createDetailAccomodationInspectionConf(f), onSuccess, onErrors);
    }
  };
  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          {
            text: t('accomodationInspection.list.title'),
            link: '/accomodation-inspection',
          },
          { text: t('accomodationInspection.detail.title') },
        ]}
      />
      <div className="header-container">
        <h2 className="page-title">{t('accomodationInspection.detail.title')}</h2>

        {(isAdministrator || isSportellista) && id && !edit && (
              <Button
                onClick={() => navigate(`/accomodation-inspection/${id}/edit`)}
                color={templateTableEditClass}
                style={{ float: 'right', marginTop: '5px' }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: '1rem' }}
                  icon={faPencil}
                />{' '}
                {t('general.buttons.edit')}
              </Button>
            )}
      </div>
      <FormWithBootstrap
        templates={templates}
        onSubmit={onFormSubmit}
        formData={datas}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        transformErrors={transformErrors}
        showErrorList={false}
        readonly={!edit}
        children={!edit}
      ></FormWithBootstrap>
      {!edit && <AddAccomodationInspectionImages></AddAccomodationInspectionImages> }
    </div>
  );
}
