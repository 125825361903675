
import { CreateAccomodationInspectionImage, DetailAccomodationInspectionImage } from '../model/accomodation-inspection-image.model';
import { defaultHeaders } from './common';

export const getAccomodationInspectionImageListConf = (
  query: string
) => {
  return {
    method: 'get',
    url:
      '/api/accomodation-inspection-image' + query ,
    headers: defaultHeaders,
  };
};

export const getDetailAccomodationInspectionImageConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection-image/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailAccomodationInspectionImageConf = (
  accomodationImage: DetailAccomodationInspectionImage
) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection-image/${accomodationImage.id}`,
    headers: defaultHeaders,
    data:  accomodationImage
  };
};

export const createDetailAccomodationInspectionImageConf = (
  accomodationImage: CreateAccomodationInspectionImage
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection-image`,
    headers: defaultHeaders,
    data: accomodationImage,
  };
};
export const deleteAccomodationInspectionImageConf = (
  id: string
) => {
  return {
    method: 'delete',
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection-image/`+id,
    headers: defaultHeaders
  };
};
