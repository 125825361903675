import { Route } from 'react-router-dom';
import { currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import SurveyTemplateListPage from './survey-template-list.page';
import SurveyTemplateDetailPage from './survey-template-detail.page';
import SurveyTemplateEditPage from './survey-template-edit.page';
import SurveyTemplateAnswerPage from './survey-template-answer.page';

const SurveyTemplateRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  const isAdministrator = currentUser?.authorityChecks.isAdministrator
  const isSportellista = currentUser?.authorityChecks.isSportellista 

  return (
    <ErrorBoundaryRoutes>
      {currentUser && (isSportellista || isAdministrator) && (
        <>
          <Route path=":id/answer" element={<SurveyTemplateAnswerPage />} />
        </>
      )}
      {currentUser && isAdministrator && (
        <>
          <Route path="" element={<SurveyTemplateListPage />} />
          <Route path="create" element={<SurveyTemplateEditPage />} />
          <Route path=":id" element={<SurveyTemplateDetailPage />} />
          <Route path=":id/edit" element={<SurveyTemplateEditPage />} />
          <Route path=":id/answer" element={<SurveyTemplateAnswerPage />} />
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default SurveyTemplateRoutes;
