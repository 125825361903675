import { DetailRealEstateAgency } from '../model/real-estate-agency.model';
import { defaultHeaders } from './common';

export const getRealEstateAgencyListConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: 'get',
    url:
      '/api/real-estate-agency?page=' +
      page +
      '&size=' +
      size +
      query +
      '&sort=id,desc',
    headers: defaultHeaders,
  };
};
export const getDetailRealEstateAgencyConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/real-estate-agency/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailRealEstateAgencyConf = (
  realEstateAgency: DetailRealEstateAgency
) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: `/api/real-estate-agency/${realEstateAgency.id}`,
    headers: defaultHeaders,
    data: JSON.stringify(realEstateAgency),
  };
};

export const createDetailRealEstateAgencyConf = (
  realEstateAgency: DetailRealEstateAgency
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: `/api/real-estate-agency`,
    headers: defaultHeaders,
    data: JSON.stringify(realEstateAgency),
  };
};
