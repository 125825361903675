import { ArrayFieldTemplateProps, FieldTemplateProps, ObjectFieldTemplateProps, RJSFSchema } from '@rjsf/utils';
import MonacoEditor from '@monaco-editor/react';

import { Templates } from '@rjsf/bootstrap-4';
import CustomFieldTemplate from './templates/LayoutFieldTemplate';
import  ArrayFieldTemplateCustomButtons from './templates/ArrayFieldTemplate';
import {
  getUiOptions,
  FormContextType,
  getTemplate,
  StrictRJSFSchema,
} from '@rjsf/utils';
import Form from 'react-bootstrap/Form';


export const ObjectFieldTemplate = (props: ObjectFieldTemplateProps<any, RJSFSchema>) => {
  const { uiSchema } = props;
  const options = getUiOptions(uiSchema);
  // console.log('ObjectFieldTemplate options', options);
  let SelectedTemplate : any = Templates.ObjectFieldTemplate

  if( options && options['customTemplate'] && options['customTemplate'] == 'MultiColumns')
    SelectedTemplate = CustomFieldTemplate 

  return SelectedTemplate(props);
}

export const ArrayFieldTemplate = (props: ArrayFieldTemplateProps<any, RJSFSchema>) => {
  const { uiSchema } = props;
  const options = getUiOptions(uiSchema);
 // console.log(options);

  let SelectedTemplate : any = Templates.ArrayFieldTemplate

  if( options && options['customTemplate'] && options['customTemplate'] == 'CustomButtons')
    SelectedTemplate = ArrayFieldTemplateCustomButtons 

  
  return SelectedTemplate(props);
}

export const FieldTemplate = (props: FieldTemplateProps<any, RJSFSchema>) => {
  const { uiSchema } = props;
  const options = uiSchema && uiSchema['ui:options']? uiSchema['ui:options'] : undefined;
  // console.log(options);

  //let SelectedTemplate : any = Templates.FieldTemplate
  let SelectedTemplate : any = FieldTemplateDefault

  if( options && options['customTemplate'] && options['customTemplate'] == 'code')
    SelectedTemplate = CodeEditorTemplate 

  return SelectedTemplate(props);
}


const CodeEditorTemplate = (props :any) => {
  const { id, label, required, schema, uiSchema, formData, onChange } = props;
 // console.log("CodeEditorTemplate", props)
  let h = "500px"

  if(props.uiSchema['ui:options'].heigth !== undefined){
    h = props.uiSchema['ui:options'].heigth + 'px'
  }

  const handleEditorChange = (newValue :any) => {
    onChange(newValue || "");
  };

  // TODO chiedere se necessario pulire il codiec dagli accapo primna di inviare 

  return (
    <div className={`mb-3 ${required ? 'required' : ''}`}>
      <label htmlFor={id}>{label}</label>
      <MonacoEditor
        height={h}
        language={uiSchema['ui:options']?.language || "json"}
        theme="vs-dark"
        value={formData}
        onChange={handleEditorChange}
        options={{ selectOnLineNumbers: true, folding: true, ...uiSchema['ui:options'] }}
      />
    </div>
  );
};


export default function FieldTemplateDefault<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  id,
  children,
  displayLabel,
  rawErrors = [],
  errors,
  help,
  description,
  rawDescription,
  classNames,
  style,
  disabled,
  label,
  hidden,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  required,
  schema,
  uiSchema,
  registry,
}: FieldTemplateProps<T, S, F>) {
  const uiOptions = getUiOptions(uiSchema);
  const WrapIfAdditionalTemplate = getTemplate<'WrapIfAdditionalTemplate', T, S, F>(
    'WrapIfAdditionalTemplate',
    registry,
    uiOptions
  );
  if (hidden) {
    return <div className='hidden'>{children}</div>;
  }
  return (
    <WrapIfAdditionalTemplate
      classNames={classNames}
      style={style}
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
      uiSchema={uiSchema}
      registry={registry}
    >
      <Form.Group>
        {displayLabel && (
          <Form.Label htmlFor={id} className={rawErrors.length > 0 ? 'text-danger' : ''}>
            {label}
            {required ? '*' : null}
          </Form.Label>
        )}
        {children}
        {displayLabel && rawDescription && (
          <Form.Text className={rawErrors.length > 0 ? 'text-danger' : 'text-muted'}>{description}</Form.Text>
        )}
        {errors}
        {help}
      </Form.Group>
    </WrapIfAdditionalTemplate>
  );
}



export const ImageWidget = (props: any) => {
  const handleChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        props.onChange(e.target.result); // base64 string
      };
      reader.readAsDataURL(file); // convert image to base64 string
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleChange} />
      {props.value && (
        <div>
          <p>Preview:</p>
          <img
            src={props.value}
            alt="preview"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      )}
    </div>
  );
};
