import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../shared/error-boundary-routes';
import PageNotFound from '../shared/page-not-found';
import AppointmentRoutes from './appointment/appointment.routes';
import BadgeRoutes from './badge/badge.routes';
import HomePage from './home/home.page';
import RealEstateAgencyRoutes from './real-estate-agency/real-estate-agency.routes';
import SurveyAnswerRoutes from './survey-answer/survey-answer.routes';
import SurveyTemplateRoutes from './survey-template/survey-template.routes';
import UserRoutes from './user/user.routes';
import AccomodationRoutes from './accomodation/accomodation.routes';
import AccomodationOwnerRoutes from './accomodation-owner/accomodation-owner.routes';
import AccomodationInspectionRoutes from './accomodation-inspection/accomodation-inspection.routes';

const PageRoutes = () => {
  return (
    <ErrorBoundaryRoutes>
      <Route index element={<HomePage />} />
      <Route path="appointment/*" element={<AppointmentRoutes />} />
      <Route path="real-estate-agency/*" element={<RealEstateAgencyRoutes />} />
      <Route path="accomodation/*" element={<AccomodationRoutes />} />
      <Route path="accomodation-inspection/*" element={<AccomodationInspectionRoutes />} />
      <Route path="accomodation-owner/*" element={<AccomodationOwnerRoutes />} />
      <Route path="survey-template/*" element={<SurveyTemplateRoutes />} />
      <Route path="survey-answer/*" element={<SurveyAnswerRoutes />} />
      <Route path="user/*" element={<UserRoutes />} />
      <Route path="badge/*" element={<BadgeRoutes />} />
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default PageRoutes;
