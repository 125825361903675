import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useRequest from "../../hook/use-request.hook";
import { ApiError } from "../../model/errors.model";
import { useAppUtilContext } from "../../util/app-util.context";
import { getDetailSurveyTemplateConf } from "../../api/surveyTemplateAPI";
import { ErrorsView } from "../../components/errors.view";
import { DetailSurveyTemplate } from "../../model/survey-template.model";
import { Wizard } from "../../components/wizard";
import { withTheme } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { useNavigate } from "react-router-dom";
import { Theme as Bootstrap4Theme } from "@rjsf/bootstrap-4";
import BreadcrumbsUtility from "../../components/breadcrumb";
import { useAppSelector } from "../../redux/store";
import { userLngSelector } from "../../redux/reducers/userSlice";
import { tradKey } from "./survey-template-edit.page";
import MobileButton from "../../components/mobile-button";
import { templateTableEditClass } from "../../util/form.util";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

const FormWithBootstrap = withTheme(Bootstrap4Theme);

export default function SurveyTemplateDetailPage() {
  let { id } = useParams();
  const { showModal } = useAppUtilContext();
  const { fetchData: fetchDataDetail } = useRequest<DetailSurveyTemplate>();
  const [entity, setEntity] = useState<DetailSurveyTemplate>();
  const formRef = useRef<any>(null);
  const navigate = useNavigate();

  const [uis, setUis] = useState<any[]>([]);
  const [step, setStep] = useState<any>(0);
  const [forms, setForms] = useState<any[]>([]);
  const [datas, setDatas] = useState<any[]>([]);
  const lang = useAppSelector(userLngSelector);

  useEffect(() => {
    if (entity) {
      let schemaObj = entity.jsonSchema;
      let uiObj = entity.uiSchema;
      let trad = entity.tradSchema;

      if (entity.translated && lang) {
        let tradSchema = tradKey(schemaObj, trad, lang);
        let tradUi = tradKey(uiObj, trad, lang);

        setUis(tradUi);
        setForms(tradSchema);
      } else {
        setUis(uiObj);
        setForms(schemaObj);
      }
    }
  }, [entity, lang]);

  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailSurveyTemplateConf(id),
        onSuccessDetail,
        onErrorsDetail
      );
    }
  }, [id]);

  const onSuccessDetail = (data: DetailSurveyTemplate) => {
    let ent = { ...data };
    setEntity(ent);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t("modal.request_errors"),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };
  const maxstep = forms.length;
  const onSubmit = ({ formData }: any) => {
    let d = datas;
    datas[step] = formData;
    setDatas(datas);

    if (step < maxstep - 1) {
      setStep(step + 1);
    } else {
      alert("You submitted " + JSON.stringify(datas, null, 2));
    }
  };
  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          { text: t("survey-template.list.title"), link: "/survey-template" },
          { text: t("survey-template.detail.title") },
        ]}
      />

      <div className="header-container">
        <h2 className="page-title">
          {t("survey-template.detail.title")}

          <div style={{ float: "right" }}>
            <MobileButton
              inTable={true}
              icon={faPencil}
              className="d-block"
              style={{ width: "100%" }}
              color={templateTableEditClass}
              size="sm"
              text={t("general.buttons.edit")}
              id={"view" + id}
              onClick={() => navigate(`/survey-template/${id}/edit`)}
            />
          </div>
        </h2>
      </div>
      {forms.length > 0 && (
        <div>
          <Wizard
            initialStep={step}
            titles={forms.map((el, index) => el.title)}
            onStepClick={(index: any) => {
              let err = formRef.current.validateForm();

              if (err) setStep(index);
            }}
          />
          <div className="card">
            <div className="card-body">
              <FormWithBootstrap
                ref={formRef}
                formData={datas[step]}
                schema={forms[step]}
                uiSchema={uis[step]}
                validator={validator}
                readonly={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
