import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { ApiError } from '../model/errors.model';

function useRequest<T>() {
  const [loading, setLoading] = useState(false);

  const fetchData = async (
    config: AxiosRequestConfig,
    onSuccess: (data: T, response?: any, totRes?: any) => void,
    onErrors: (errors: ApiError[]) => void
  ) => {
    setLoading(true);
    try {
      const response = await axios.request<T>(config);
      onSuccess(response.data, response.headers, response);
    } catch (err) {
      let errors = [{ message: err + '' }];
      if (err instanceof AxiosError) {
        if (err.response && err.response.data && err.response.data.errors) {
          errors = err.response.data.errors;
        } else {
          errors = [{ message: err.message }];
        }
      } else if (err instanceof Error) {
        errors = [{ message: err.message }];
      }
      onErrors(errors);
    } finally {
      setLoading(false);
    }
  };

  return { loading, fetchData };
}

export default useRequest;
