import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormValidation, RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import {
  createUserInfo,
  getDetailUserConf,
  updateUserInfo,
} from "../../api/userAPI";
import BreadcrumbsUtility from "../../components/breadcrumb";
import useRequest from "../../hook/use-request.hook";
import {
  Authorities,
  currentUserSelector,
} from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import {
  NotificationTypes,
  useAppUtilContext,
} from "../../util/app-util.context";
import {
  FormWithBootstrap,
  templates,
  transformErrors,
} from "../../util/form-util";
import { templateTableEditClass } from "../../util/form.util";
import HomeAppointmentListPage from "../home/appointment-list.page";
import SurveyAnswerListPage from "./answer-list.page";

export default function RegisterPage(props: any) {
  const { blocked } = props;
  let { id } = useParams();
  const { t } = useTranslation();
  const { showModal } = useAppUtilContext();
  const { showNotification } = useAppUtilContext();
  const currentUser = useAppSelector(currentUserSelector);
  const [entity, setEntity] = useState<any>({});
  const [formData, setFormData] = useState<any>({});

  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();

  const isAdministrator = currentUser?.authorityChecks.isAdministrator;
  const isSportellista = currentUser?.authorityChecks.isSportellista;

  useEffect(() => {
    if (id) {
      fetchData(
        getDetailUserConf(id),
        (data: any, header: any) => {
          setEntity(data);
          setFormData(data);
        },
        onErrors
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t("modal.request_success"), message, null);
    isAdministrator ? navigate("/user") : navigate("/");
  };

  const onErrors = (errors: any) => {
    // console.log('errors', errors);
    showNotification(errors[0].message, NotificationTypes.ERROR); // Shows first error message in errors array (missing passwords, etc.)
  };

  const onSubmit = (data: any) => {
    setFormData(data);
    const { email, firstName, lastName, authorities, fiscalCode } = data;

    let auth = isAdministrator ? authorities : [];
    if (id) {
      fetchData(
        updateUserInfo({
          id: parseInt(id),
          firstName,
          lastName,
          email,
          authorities: auth,
          fiscalCode,
        }),
        onSuccess,
        onErrors
      );
    } else {
      // if (!isAdministrator) auth = ["USER"];

      fetchData(
        createUserInfo({
          email,
          firstName,
          lastName,
          authorities: auth,
          fiscalCode,
        }),
        onSuccess,
        onErrors
      );
    }
  };

  const fullName = useMemo(() => {
    return blocked
      ? (entity.firstName ? entity.firstName : "") +
          " " +
          (entity.lastName ? entity.lastName : "")
      : "Creazione";
  }, [entity]);

  const onFormSubmit = (data: any) => {
    onSubmit(data.formData);
  };

  const uiSchema = {
    authorities: {
      "ui:widget": "select",
      "ui:options": {
        multiple: "true",
      },
    },
  };

  const schema: RJSFSchema = {
    type: ["object"],
    required: ["firstName", "lastName"],
    properties: {
      firstName: {
        type: "string",
        title: t("user.register.first_name"),
      },
      lastName: {
        type: "string",
        title: t("user.register.last_name"),
      },
      fiscalCode: {
        type: "string",
        title: t("user.register.fiscalCode"),
        pattern:
          "^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$",
      },
      email: {
        type: "string",
        title: t("user.register.email"),
        format: "email",
        readOnly: entity && entity.email != null ? true : false,
      },
    },
  };

  const adminSchema: RJSFSchema = {
    type: ["object"],
    required: ["firstName", "lastName"],
    properties: {
      firstName: {
        type: "string",
        title: t("user.register.first_name"),
      },
      lastName: {
        type: "string",
        title: t("user.register.last_name"),
      },
      fiscalCode: {
        type: "string",
        title: t("user.register.fiscalCode"),
        pattern:
          "^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$",
        default: "",
      },
      email: {
        type: "string",
        title: t("user.register.email"),
        format: "email",
        readOnly: entity && entity.email != null ? true : false, //se email valorizzata e sono in edit e non in create non posso modificare
      },
      authorities: {
        type: "array",
        title: t("user.register.authorities"),
        items: {
          type: "string",
          enum: [
            Authorities.ADMIN,
            Authorities.USER,
            Authorities.SPORTELLISTA,
            Authorities.OPERATORE_COMUNE,
            Authorities.PROFESSIONISTA,
          ],
        },
        uniqueItems: true,
      },
    },
  };
  console.log("entity", entity);

  function customValidate(
    formData: any,
    errors: FormValidation<any>,
    uiSchema?: UiSchema<any, RJSFSchema, any> | undefined
  ): FormValidation<any> {
    let emailIsPresent = formData.email && formData.email !== "";
    let fiscalCodeIsPresent = formData.fiscalCode && formData.fiscalCode !== "";

    if (!emailIsPresent && !fiscalCodeIsPresent) {
      //  errors.email = "Options must be unique!";
      errors.email?.addError("Almeno un campo e' obbligatorio");
      errors.fiscalCode?.addError("Almeno un campo e' obbligatorio");
    }

    console.log(errors);
    return errors;
  }

  return (
    <>
      <div className="container mt-2">
        <BreadcrumbsUtility
          links={[
            { text: t("user.list.title"), link: "/user" },
            { text: fullName },
          ]}
        />

        <div className="header-container">
          <h2 className="page-title">
            {id
              ? blocked
                ? t("user.register.titleshow")
                : t("user.register.titlemod")
              : t("user.register.title")}

            {(isAdministrator || isSportellista) && id && blocked && (
              <Button
                onClick={() => navigate(`/user/${id}/edit`)}
                color={templateTableEditClass}
                style={{ float: "right", marginTop: "5px" }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "1rem" }}
                  icon={faPencil}
                />{" "}
                {t("general.buttons.edit")}
              </Button>
            )}
          </h2>
        </div>

        <FormWithBootstrap
          templates={templates}
          onSubmit={onFormSubmit}
          formData={formData}
          schema={isAdministrator ? adminSchema : schema}
          uiSchema={uiSchema}
          validator={validator}
          transformErrors={transformErrors}
          showErrorList={false}
          readonly={blocked || loading}
          focusOnFirstError={true}
          customValidate={customValidate}
        ></FormWithBootstrap>
      </div>
      <div className="container mt-2">
        {blocked ? <HomeAppointmentListPage monthView fixedUser={id} /> : ""}
        {blocked ? <SurveyAnswerListPage user={id} /> : ""}
      </div>
    </>
  );
}
