import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { geCurrentUserConf } from '../../api/userAPI';
import useRequest from '../../hook/use-request.hook';
import { currentUserSuccess } from '../../redux/reducers/userSlice';
import { AppThunkDispatch } from '../../redux/store';
import { useAppUtilContext } from '../../util/app-util.context';
import BadgePersonal from '../badge/badge-personal';
import BadgeListPage from './badge-list.page';

export default function BadgeInOut() {
  const dispatch = useDispatch() as AppThunkDispatch;
  const { showModal } = useAppUtilContext();
  const { t } = useTranslation();
  const badgeListRef = useRef<any>(null);
  const { fetchData } = useRequest();

  const onSuccessRefresh = (data: any) => {
    dispatch(currentUserSuccess(data));
  };

  const onErrors = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_failed'), errorsView, null);
  };

  return (
    <div className="container mt-2">
      <BadgePersonal
        onCreate={() => {
          badgeListRef.current?.refresh();
          fetchData(geCurrentUserConf(), onSuccessRefresh, onErrors);
        }}
      />
      <BadgeListPage ref={badgeListRef} />
    </div>
  );
}
