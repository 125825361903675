import { Route } from 'react-router-dom';
import { currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import SurveyAnswerListPage from './survey-answer-list.page';
import SurveyAnswerDetailPage from './survey-answer-detail.page';
import SurveyAnswerConfirmPage from './survey-answer-confirm.page';
import SurveyAnswerDetailListPage from './survey-answer-detail-list.page';
import ArchivedSurveyAnswerDetailPage from './archived-survey-answer-detail.page';

const SurveyAnswerRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);

  const isOperatore = currentUser?.authorityChecks.isOperatore 

  return (
    <ErrorBoundaryRoutes>
      {currentUser && (
        <>
          <Route path="" element={<SurveyAnswerListPage />} />
          <Route path=":id" element={<SurveyAnswerDetailPage />} />
          <Route path=":id/versions" element={<SurveyAnswerDetailListPage />} />
          <Route path=":id/versions/:archivedId" element={<ArchivedSurveyAnswerDetailPage />} />
          {! isOperatore && <Route path=":id/edit" element={<SurveyAnswerDetailPage edit />} /> }
          <Route path=":id/SMSconfirm" element={<SurveyAnswerConfirmPage />} />
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default SurveyAnswerRoutes;
