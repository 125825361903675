import {
  faEye,
  faPencil,
  faPlus,
  faRefresh,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Spinner,
  Table,
} from 'reactstrap';
import BreadcrumbsUtility from '../../components/breadcrumb';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import PaginationUtility from '../../components/pagination';
import useRequest from '../../hook/use-request.hook';
import { useAppUtilContext } from '../../util/app-util.context';
import { templateTableEditClass, templateTableShowClass } from '../../util/form.util';
import { getAccomodationOwnerListConf } from '../../api/accomodationOwnerAPI';
import { DetailAccomodationOwner } from '../../model/accomodationOwner.model';

export default function AccomodationOwnerListPage() {
  const { onErrorsModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<DetailAccomodationOwner[]>([]);
  const [name, setName] = useState<any>(null);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  useEffect(() => {
    refreshFirstPage();
  }, []);

  const handleChange = (e: any) => {
    if (e.target.id === 'name') setName(e.target.value);
  };

  const refreshFirstPage = () => {
    setPagination({
      page: 1,
      pageSize: 21,
      totalElements: null,
    });

    refresh(pagination);
  };

  const refresh = (pag: any) => {
    let query = '';
    if (name) query = query + '&name=' + name;
    // if (surname) query = query + '&surname=' + surname;

    fetchData(
      getAccomodationOwnerListConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(data);
        setPagination({
          ...pag,
          ...{ totalElements: header['x-total-count'] },
        });
      },
      onErrorsModal
    );
  };

  return (
    <div className="container mt-2">
      <BreadcrumbsUtility links={[{ text: t('accomodationOwner.list.title') }]} />

      <div className="header-container">
        <h2 className="page-title">{t('accomodationOwner.list.title')}</h2>
      </div>
      <div className="filter-card card mb-2">
        <div className="card-body">
          <h6 className="mt-1 mb-3">{t('general.actions.filter')}</h6>
          <div className="mt-2">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-5">
                <FormGroup>
                  <div
                    style={{ marginBottom: '.5rem', display: 'inline-block' }}
                  >
                    {t('general.name')}
                  </div>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2">
                <div style={{ marginBottom: '.5rem' }}>&nbsp;</div>
                <Button color="primary" onClick={refreshFirstPage}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="justify-content-end pt-3"
        style={{ display: 'flex', gap: '10px' }}
      >
        <MobileButton
          color="secondary"
          outline
          onClick={() => navigate(`/accomodation-owner/create`)}
        >
          <FontAwesomeIcon icon={faPlus} />
          <span className="d-none d-md-inline">
            &nbsp; {t('general.buttons.create')}
          </span>
        </MobileButton> 
        <MobileButton
          onClick={() => refresh(pagination)}
          disabled={loading}
          color="secondary"
          outline
        >
          {!loading ? (
            <>
              <FontAwesomeIcon icon={faRefresh} />
              <span className="d-none d-md-inline">
                &nbsp; {t('general.buttons.refresh')}
              </span>
            </>
          ) : (
            <>
              {/* <Spinner size="sm">Loading...</Spinner>
              <span className="d-none d-md-inline">
                {' '}
                {t('general.actions.loading')}{' '}
              </span> */}
              <svg viewBox="25 25 50 50" className='svgcircle d-none d-md-inline mt-2'>
                <circle r="20" cy="50" cx="50"></circle>
              </svg>
            </>
          )}
        </MobileButton>
      </div>
      {pagination.totalElements == 0 && (
        <div className="pb-3 pt-3 fs-5">
          {t('general.errors.no_items_found')}
        </div>
      )}
      {pagination.totalElements > 0 && !loading && (
        <div className="mt-2 row">
          <Table striped responsive>
            <thead>
              <tr style={{ whiteSpace: 'break-spaces' }}>
                <th style={{ width: '7%' }}>{t('general.id')}</th>
                <th style={{ width: '20%' }}>{t('accomodationOwner.name')}</th>
                <th style={{ width: '20%' }}>{t('accomodationOwner.email')}</th>

                <th rowSpan={2} style={{ width: '13%' }}></th>
              </tr>
              <tr style={{ whiteSpace: 'break-spaces' }}></tr>
            </thead>
            <tbody>
              {list.map((it, idx) => {
                return (
                  <tr key={it.id}>
                    <td style={{ width: 'auto' }}>{it.id}</td>
                    <td style={{ width: 'auto' }}>{it.name} {it.surname}</td>
                    <td style={{ width: 'auto' }}>{it.emails?.join(", ")} </td>

                    <td>
                      <ButtonGroup style={{ width: '100%' }}>
                        <MobileButton
                          inTable={true}
                          icon={faEye}
                          className="d-block"
                          style={{ width: '100%' }}
                          
                          color={templateTableShowClass}
                          size="sm"
                          text={t('general.buttons.show')}
                          id={'view' + it.id}
                          onClick={() =>
                            navigate(`/accomodation-owner/${it.id}`)
                          }
                        />
                        <MobileButton
                          inTable={true}
                          icon={faPencil}
                          className="d-block"
                          style={{ width: '100%' }}
                          
                          color={templateTableEditClass}
                          size="sm"
                          text={t('general.buttons.edit')}
                          id={'view' + it.id}
                          onClick={() =>
                            navigate(`/accomodation-owner/${it.id}/edit`)
                          }
                        />
                      </ButtonGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      <div className="pb-5">
        <PaginationUtility
          {...pagination}
          onSizeChange={(n: any) => {
            if (pagination.pageSize !== n)
              refresh({ ...pagination, ...{ page: 1, pageSize: n } });
          }}
          onChange={(n: any) => {
            if (pagination.page !== n)
              refresh({ ...pagination, ...{ page: n } });
          }}
        />
      </div>
    </div>
  );
}
