import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonGroup } from 'reactstrap';
import useRequest from '../../hook/use-request.hook';
import { ApiError } from '../../model/errors.model';
import { useAppUtilContext } from '../../util/app-util.context';
import {
  createDetailSurveyTemplateConf,
  getDetailSurveyTemplateConf,
  updateDetailSurveyTemplateConf,
} from '../../api/surveyTemplateAPI';
import { ErrorsView } from '../../components/errors.view';
import {
  CreateSurveyTemplate,
  DetailSurveyTemplate,
} from '../../model/survey-template.model';
import { withTheme } from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';

import { Theme as Bootstrap4Theme } from '@rjsf/bootstrap-4';
import SurveyTemplateStatic from './survey-template-static';
import { RJSFSchema } from '@rjsf/utils';
import { FieldTemplate } from '../../components/TemplateFieldShifter';
import MobileButton from '../../components/mobile-button';
import { faEye, faPencil, faLanguage } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbsUtility from '../../components/breadcrumb';
import JSONFieldGenerator from './JSON-field-generator';

const FormWithBootstrap = withTheme(Bootstrap4Theme);

function extractKey(data: any, keyToFind: string[]) {
  let titles: any = [];

  function recursiveSearch(node: any) {
    if (Array.isArray(node)) {
      node.forEach((item) => recursiveSearch(item));
    } else if (typeof node === 'object' && node !== null) {
      for (let key in node) {
        if (keyToFind.includes(key)) {
          titles.push(node[key]);
        }
        if (typeof node[key] === 'object' || Array.isArray(node[key])) {
          recursiveSearch(node[key]);
        }
      }
    }
  }

  recursiveSearch(data);
  return titles;
}

export function trarrrdKey(data: any, dictionary: any, language: string) {
  function recursiveSostitution(node: any) {
    if (Array.isArray(node)) {
      node.forEach((item) => recursiveSostitution(item));
    } else if (typeof node === 'object' && node !== null) {
      for (let key in node) {
        if (typeof node[key] === 'object' || Array.isArray(node[key])) {
          recursiveSostitution(node[key]);
        }
      }
    }
  }

  recursiveSostitution(data);
  return data;
}

export function tradKey(obj: any, dictionary: any, language: string) {
  // console.log("tradKey", obj, dictionary,language)
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  const newObj: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    // console.log("tradKey: key - obj", key, obj)

   if(key === 'const' || key === 'enum'){
     newObj[key] = obj[key]
   } else 
    if (dictionary[obj[key]] !== undefined) {
      if (
        dictionary[obj[key]][language] !== undefined &&
        dictionary[obj[key]][language] !== ''
      ) {
        newObj[key] = dictionary[obj[key]][language];
      } else {
        newObj[key] = dictionary[obj[key]]['neutral'];
      }
    } else {
      newObj[key] = tradKey(obj[key], dictionary, language);
    }
  }

  return newObj;
}

export function isStringified(jsonValue: any) {
  try {
    return JSON.parse(jsonValue);
  } catch (err) {
    return {
      it: jsonValue,
      en: jsonValue,
    };
  }
}

export default function SurveyTemplateEditPage() {
  let { id } = useParams();
  const { showModal, onErrorsModal } = useAppUtilContext();
  const { loading, fetchData } = useRequest<DetailSurveyTemplate>();
  const [entity, setEntity] = useState<
    DetailSurveyTemplate | CreateSurveyTemplate
  >();
  const formRef = useRef<any>(null);
  const [uis, setUis] = useState<any[]>([{}]);
  const [forms, setForms] = useState<any[]>([{}]);
  const [traslation, setTraslation] = useState<any>({});
  const [saveNext, setSaveNext] = useState<boolean>(false);
  const [transNext, setTransNext] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchData(
        getDetailSurveyTemplateConf(id),
        onSuccessDetail,
        onErrorsModal
      );
    } else {
      setEntity({
        title: {
          it: '',
          en: '',
        },
        uiSchema: '[{}]',
        jsonSchema: '[{}]',
        enabled: false,
        translated: false,
        tradSchema: '{}'
        // wordFile: '',
      });
    }
  }, [id]);

  const onSuccessDetail = (data: DetailSurveyTemplate) => {
    let ent = { ...data };

    ent.jsonSchema = JSON.stringify(data.jsonSchema);
    ent.uiSchema = JSON.stringify(data.uiSchema);
    ent.tradSchema = JSON.stringify(data.tradSchema);

    let title = isStringified(ent.title);
    //  ent.tradSchema = data.tradSchema === 'null' ? '{}' : data.tradSchema;
    setEntity({ ...ent, ...{ title: title } });
  };

  const translateData = (ev: any) => {
    setTransNext(true);
  };

  const saveData = (ev: any) => {
    setSaveNext(true);
  };

  const onChange = (props: any) => {
    const { formData } = props;

    // const xx= formRef.current?.validateForm();
    // console.log("onChange formData", formData, xx)
    setEntity(formData);
  };

  const onSubmit = ({ formData }: any) => {
    // console.log('onSubmit formData', formData);
    setUis(JSON.parse(formData.uiSchema));
    setForms(JSON.parse(formData.jsonSchema));
    setTraslation(formData.tradSchema ? JSON.parse(formData.tradSchema) : '{}');

    let ent: any = { ...entity };
    ent.title = formData.title;
    ent.jsonSchema = formData.jsonSchema;
    ent.uiSchema = formData.uiSchema;
    ent.tradSchema = formData.tradSchema;

    if (saveNext) {
      let newEntity: DetailSurveyTemplate | CreateSurveyTemplate = {
        //  id: entity && entity.id? entity.id: undefined,
        title: JSON.stringify(formData.title),
        uiSchema: JSON.parse(formData.uiSchema),
        jsonSchema: JSON.parse(formData.jsonSchema),
        enabled: formData.enabled,
        translated: formData.translated,
        tradSchema: JSON.parse(formData.tradSchema),
        wordFile: formData.wordFile,
      };

      if (id) {
        (newEntity as DetailSurveyTemplate).id = parseInt(id);
        fetchData(
          updateDetailSurveyTemplateConf(newEntity as DetailSurveyTemplate),
          onSuccessEdit,
          onErrorsModal
        );
      } else {
        fetchData(
          createDetailSurveyTemplateConf(newEntity as CreateSurveyTemplate),
          onSuccessCreate,
          onErrorsModal
        );
      }
    } else if (transNext) {
      let form = JSON.parse(formData.jsonSchema);
      let ui = JSON.parse(formData.uiSchema);
      let v = extractKey(
        [...form, ...ui],
        ['title', 'description', 'ui:placeholder']
      );

      let trad =
        formData.tradSchema !== '' ? JSON.parse(formData.tradSchema) : {};

      v.forEach((el: any) => {
        if (!trad.hasOwnProperty(el) && el !== '') {
          trad[el] = {
            it: '',
            en: '',
            neutral: el,
          };
        }
      });

      let tText = JSON.stringify(trad);
      formRef.current.onChange({ ...ent, ...{ tradSchema: tText } });
    }
  };

  const onSuccessEdit = (data: any) => {
    setSaveNext(false);

    showModal(t('modal.request_success'), data.message, null);
  };

  const onSuccessCreate = (data: any) => {
    setSaveNext(false);
    
    navigate(`/survey-template/${data.data?.id}`);
  };

  const schema: RJSFSchema = {
    type: 'object',
    properties: {
      title: {
        type: 'object',
        title: '',
        properties: {
          it: { type: 'string', title: 'Titolo IT' },
          en: { type: 'string', title: 'Titolo EN' },
        },
        required: ['it', 'en'],
      },
      enabled: {
        title: 'Attivo',
        type: 'boolean',
      },
      jsonSchema: { title: 'Schema', type: 'string' },
      uiSchema: { title: 'UI Schema', type: 'string' },
      translated: {
        title: 'Questionario multilingua',
        type: 'boolean',
        default: false,
      },
      tradSchema: { title: 'Traduzioni', type: 'string' },
      wordFile: {
        type: 'string',
        format: 'data-url',
        title: 'Word file',
      },
    },
  };

  const uiSchema: any = {
    uiSchema: {
      'ui:options': {
        customTemplate: 'code',
        language: 'json', // Additional options specific for Monaco Editor
      },
    },
    jsonSchema: {
      'ui:options': {
        customTemplate: 'code',
        language: 'json', // Additional options specific for Monaco Editor
      },
    },
    tradSchema: {
      'ui:options': {
        customTemplate: 'code',
        language: 'json', // Additional options specific for Monaco Editor
      },
    },
  };

  return (
    <div className="container mt-2">
      <BreadcrumbsUtility
        links={[
          { text: t('survey-template.list.title'), link: '/survey-template' },
          { text: t('survey-template.detail.title') },
        ]}
      />
      <div className="header-container">
        <h2 className="page-title">{t('survey-template.detail.title')}</h2>
      </div>

      <FormWithBootstrap
        ref={formRef}
        formData={entity}
        onSubmit={onSubmit}
        onChange={onChange}
        schema={schema}
        templates={{
          FieldTemplate,
        }}
        uiSchema={uiSchema}
        validator={validator}
      >
        <JSONFieldGenerator />
        <ButtonGroup style={{ marginBottom: '2rem', width: '100%' }}>
          {entity && entity.translated && (
            <MobileButton
              icon={faLanguage}
              color={'primary'}
              size="lg"
              outline
              type="submit"
              onClick={translateData}
              text={t('general.buttons.translate')}
            />
          )}
          <MobileButton
            icon={faEye}
            color={'primary'}
            size="lg"
            outline
            type="submit"
            text={t('general.buttons.refresh-preview')}
          />
          <MobileButton
            icon={faPencil}
            size="lg"
            color={'primary'}
            onClick={saveData}
            text={t('general.buttons.edit')}
            type="submit"
          />
        </ButtonGroup>
      </FormWithBootstrap>

      {forms.length > 0 && (
        <div>
          <div className="card">
            <div className="card-body">
              <SurveyTemplateStatic
                onSubmit={() => {}}
                jsonSchema={forms}
                uiSchema={uis}
                tradSchema={traslation}
                isTradEnabled={false}
                isReadMode={false}
                formDatas={{}}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
