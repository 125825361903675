import { createContext, useContext, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OfflineAlert from '../components/offline-alert';
import GenericModal, { ModalHandle } from '../modal/generic.modal';
import { ApiError } from '../model/errors.model';
import { ErrorsView } from '../components/errors.view';
import { t } from 'i18next';

export enum NotificationTypes {
  SUCCESS = 'succes',
  ERROR = 'error',
}
// Create a context
const AppUtilContext = createContext({
  showNotification: (text: string, type: string) => {},
  showModal: (title: string, body: any, footer: any, action?: any) => {},
  closeModal: () => {},
  onErrorsModal: (errors: ApiError[]) => {},
  onSuccessModal : (data: any) => {}
});

// Custom hook to access the context
export const useAppUtilContext = () => useContext(AppUtilContext);

// Provider component to wrap around the components that need access to the context
export const AppUtilContextProvider = ({ children }: any) => {

  const genericModalRef = useRef<ModalHandle>(null);
  // Define your method here
  const showNotification = (text: string, type: string) => {
    // Method logic
    if (type === NotificationTypes.SUCCESS) {
      toast.success(text);
    } else if (type === NotificationTypes.ERROR) {
      toast.error(text);
    }
  };

  const showModal = (title: string, body: any, footer: any, action?: any ) => {
    if(action)
      genericModalRef.current?.open(title, body, footer, action);
    else 
      genericModalRef.current?.open(title, body, footer);
  };

  const closeModal = () => {
      genericModalRef.current?.close();
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSuccessEdit = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
  };

  // Value object to provide to the context
  const contextValue = {
    showNotification: showNotification,
    showModal: showModal,
    closeModal: closeModal,
    onErrorsModal: onErrorsDetail,
    onSuccessModal: onSuccessEdit,
  };

  return (
    <AppUtilContext.Provider value={contextValue}>
      {children}
      <GenericModal ref={genericModalRef} />
      <ToastContainer />
      <OfflineAlert></OfflineAlert>
    </AppUtilContext.Provider>
  );
};
