import { DetailAccomodationInspection } from '../model/accomodation-inspection.model';
import { defaultHeaders } from './common';

export const getAccomodationInspectionListConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: 'get',
    url:
      '/api/accomodation-inspection?page=' +
      page +
      '&size=' +
      size +
      query +
      '&sort=id,desc',
    headers: defaultHeaders,
  };
};
export const getDetailAccomodationInspectionConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection/${id}`,
    headers: defaultHeaders,
  };
};

export const updateDetailAccomodationInspectionConf = (
  accomodation: DetailAccomodationInspection
) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection/${accomodation.id}`,
    headers: defaultHeaders,
    data: JSON.stringify(accomodation),
  };
};

export const createDetailAccomodationInspectionConf = (
  accomodation: DetailAccomodationInspection
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: `/api/accomodation-inspection`,
    headers: defaultHeaders,
    data: JSON.stringify(accomodation),
  };
};
