import axios from 'axios';
import { SERVER_API_URL } from './config';
import { logout, signinSuccess } from '../redux/reducers/userSlice';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

export const setupAxiosInterceptors = (store: any) => {
  axios.interceptors.request.use((config) => {
    let state = store.getState();

    let token = state?.user?.accessToken;
    let lang = state?.user?.lang;
    if (token !== null) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    if (lang !== null) {
      config.headers['Accept-Language'] = lang;
    }
    return config;
  });
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          let state = store.getState();

          let refreshToken = state?.user?.refreshToken;
          const response = await axios.post('/api/auth/refresh-token', {
            refreshToken,
          });
          const { accessToken } = response.data;
          store.dispatch(signinSuccess(response.data));
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        } catch (error) {
          store.dispatch(logout());
        }
      }

      return Promise.reject(error);
    }
  );
};
